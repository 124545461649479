.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Bender';
  src: url('../fonts/Bender.woff2') format('woff2'),
    url('../fonts/Bender.woff') format('woff');

  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bender Bold';
  src: url('../fonts/Bender-Bold.woff2') format('woff2'),
    url('../fonts/Bender-Bold.woff') format('woff');

  font-style: normal;
  font-display: swap;
}

html {
  color: #707070;
  font-size: 1em;
  line-height: 1.4;
  height: 100%;
  padding: 0;
  margin: 0;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

body {
  background-color: transparent !important;
  font: 16px/26px Helvetica, Helvetica Neue, Arial;
  padding: 0;
  margin: 0;
}

.wrapper {
  width: calc(100% - 30px);
  margin: 0 15px;
}

.header-container header {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.mobile-menu .menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  width: calc(100% - 30px);
}

.header-container,
.footer-container,
.main aside {
  background: rgba(0, 0, 0, 0.8);
}

a.logo,
a.logo:hover,
a.logo:active,
a.logo:visited {
  display: inline-block;
  padding: 20px 0 20px 55px;
  background: url(../img/fence-logo.png) no-repeat center left;
  color: #7ab903;
  font-family: 'Khand', sans-serif;
  text-transform: uppercase;
  font-size: 2.25em;
  line-height: 1em;
  font-weight: 600;
  text-decoration: none;
  background-size: 38px 43px;
}

header nav {
  display: inline-flex;
  align-items: center;
  justify-content: right;
}

a.btn-login,
a.btn-primary,
button.btn-primary,
a.btn-secondary,
button.btn-secondary,
a.btn-login:hover,
a.btn-primary:hover,
button.btn-primary:hover,
a.btn-secondary:hover,
button.btn-secondary:hover,
a.btn-login:active,
a.btn-primary:active,
button.btn-primary:active,
a.btn-secondary:active,
button.btn-secondary:active,
a.btn-login:visited,
a.btn-primary:visited,
button.btn-primary:visited,
a.btn-secondary:visited,
button.btn-secondary:visited {
  background: #7ab903;
  color: #000 !important;
  padding: 8px 20px;
  border: none;
  border-radius: 3px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  font-style: normal;
  font-size: 1em;
  line-height: 1em;
  cursor: pointer;
}

.modal-content a {
  color: #7ab903;
}

a.btn-secondary,
button.btn-secondary {
  background-color: #625f48 !important;
}

button.btn-cancel,
button.btn-cancel:hover,
button.btn-cancel:active,
button.btn-cancel:visited {
  background: #e41a1a;
}

button.btn-grey,
button.btn-grey:hover,
button.btn-grey:active,
button.btn-grey:visited {
  background: #707070;
}

a.btn-login {
  overflow: hidden;
  position: relative;
  z-index: 1;
}
a.btn-login span {
  z-index: 20;
}

a.btn-login::after,
a.btn-login::before {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  content: '';
  height: 155px;
  left: -275px;
  opacity: 0.2;
  position: absolute;
  top: -50px;
  transition: all 1500ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 250px;
  z-index: -10;
}

a.btn-login:hover::after,
a.btn-login:hover::before {
  left: 200px;
  transition: all 1500ms cubic-bezier(0.19, 1, 0.22, 1);
}

nav a:hover,
nav a:visited {
  color: #707070;
}

nav a:hover {
  text-decoration: underline;
}

.main article h1 {
  font-size: 2em;
}

.main aside {
  color: #707070;
  padding: 0px 5% 10px;
}

.footer-container footer {
  color: #707070;
  padding: 20px 0;
}

.tagline-social {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tagline {
  display: inline-block;
  flex-grow: 1;
  text-transform: uppercase;
  font-family: 'Bender Bold';

  font-style: normal;
  padding: 20px 0;
  font-size: 0.95em;
  color: #999;
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: right;
}

.social-link {
  background-color: rgba(9, 9, 7, 0.9);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.social-link a {
  display: block;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.social-link::after,
.social-link::before {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(43, 43, 43, 1) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  content: '';
  height: 155px;
  left: -250px;
  opacity: 0.2;
  position: absolute;
  top: -50px;
  transition: all 4000ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 250px;
  z-index: -10;
}

.social-link:hover::after,
.social-link:hover::before {
  left: 200px;
  transition: all 4000ms cubic-bezier(0.19, 1, 0.22, 1);
}

.social-links .divider {
  width: 3px;
  height: 36px;
  background-color: rgba(9, 9, 7, 0.9);
  border-radius: 3px;
}

.social-links > div {
  margin-left: 10px;
}

.social-link img {
  margin-top: -1px;
  z-index: 20;
}

a.logo,
a.logo:hover,
a.logo:active,
a.logo:visited {
  display: inline-block;
  height: 43px;
  background: url(../img/fence-logo.png) no-repeat center left;
  color: #7ab903;
  font-family: 'Khand', sans-serif;
  text-transform: uppercase;
  font-size: 2.25em;
  line-height: 1em;
  font-weight: 600;
  text-decoration: none;
  background-size: 40px auto;
}

.logo .text {
  display: none;
}

.aside-header {
  background: #000 url(../img/aside-header-bg.jpg) repeat-x bottom left;
  border-bottom: 1px solid #000;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.aside-header span {
  padding: 8px 0 8px 47px;
  display: block;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  position: relative;
  font-style: normal;
  color: #fff;
}

.aside-header.aside-cotw {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aside-header.aside-cotw span {
  display: inline-block;
}

.aside-header.aside-cotw span.cotw-ends {
  padding: 8px 20px;
  color: #d55753;
}

.aside-header.aside-cotw span.cotw-ends span {
  padding: 0;
  color: #d55753;
}

.aside-header.aside-cotw span.cotw-ends span.timer-label {
  padding: 0 3px;
}

.aside-header span .MuiSvgIcon-root {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
}

.aside-header .menu {
  background: url(../img/icon-menu.png) no-repeat 15px center;
  background-size: 18px;
}

.aside-header .cotw {
  background: url(../img/icon-crown.png) no-repeat 15px center;
  background-size: 18px;
}

.aside-header .stats {
  background: url(../img/icon-stats-green.png) no-repeat 15px center;
  background-size: 18px;
}

.aside-header .donate {
  background: url(../img/icon-donate-green.png) no-repeat 15px center;
  background-size: 18px;
}

.aside-header .review {
  background: url(../img/icon-vods-green.png) no-repeat 15px center;
  background-size: 18px;
}

.aside-header .rankings {
  background: url(../img/icon-trophy-green.png) no-repeat 15px center;
  background-size: 18px;
}

.aside-header .tournaments {
  background: url(../img/icon-tournaments-green.png) no-repeat 15px center;
  background-size: 18px;
}

.aside-header .bounty {
  background: url(../img/icon-bounty-green.png) no-repeat 15px center;
  background-size: 18px;
}

.aside-header .goons {
  background: url(../img/icon-goons-green.png) no-repeat 15px center;
  background-size: 18px;
}

.aside-header .teams,
.aside-header .team {
  background: url(../img/icon-team-green.png) no-repeat 15px center;
  background-size: 18px;
}

.aside-header .events {
  background: url(../img/icon-events-green.png) no-repeat 15px center;
  background-size: 18px;
}

.aside-header .news {
  background: url(../img/icon-news-green.png) no-repeat 15px center;
  background-size: 18px;
}

.aside-header .dashboard {
  background: url(../img/icon-dashboard-green.png) no-repeat 15px center;
  background-size: 18px;
}

.aside-header .kickcom {
  background: url(../img/icon-kickcom-green.png) no-repeat 15px center;
  background-size: 18px;
}

.aside-header .admin {
  background: url(../img/icon-admin-green.png) no-repeat 15px center;
  background-size: 18px;
}

.aside-header .leagues {
  background: url(../img/icon-leagues-green.png) no-repeat 15px center;
  background-size: 18px;
}

.aside-header .live {
  background: url(../img/icon-live.png) no-repeat 15px center;
  background-size: 26px;
  padding: 8px 0 8px 58px;
}

.aside-header .premium {
  background: url(../img/icon-premium-green.png) no-repeat 15px center;
  background-size: 18px;
  padding: 8px 0 8px 40px;
}

.aside-header .credit-card {
  background: url(../img/icon-credit-card-green.png) no-repeat 15px center;
  background-size: 18px;
  padding: 8px 0 8px 40px;
}

.main-nav,
.rankings-nav,
.news-nav {
  background: rgba(9, 9, 7, 0.9);
}

.main-nav ul,
.rankings-nav ul,
.news-nav ul {
  list-style: none;
  padding: 0 0 30px;
  margin: 0;
}

.rankings-nav ul,
.news-nav > ul {
  padding: 0;
}

.main-nav li,
.rankings-nav li,
.news-nav li {
  padding: 0;
}

.main-nav li.divider,
.rankings-nav li.divider,
.news-nav li.divider {
  padding: 0 15px;
}

.main-nav li.divider div,
.rankings-nav li.divider div,
.news-nav li.divider div {
  border-bottom: 1px solid #181816;
}

.divider div {
  width: 100%;
  height: 1px;
  background: #181816;
}

.news-nav .divider div {
  width: 100%;
  height: 3px;
  background: rgba(101, 98, 74, 0.1);
}

.main-nav a,
.main-nav a:hover,
.rankings-nav a,
.rankings-nav a:hover,
.news-nav a,
.news-nav a:hover,
.news-nav .news-anchor {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Bender Bold';

  font-style: normal;
  font-size: 1em;
  line-height: 1em;
  color: #707070;
  padding: 12px 0 12px 47px;
}

.rankings-nav a,
.rankings-nav a:hover {
  padding: 12px 15px;
  display: flex;
}

.news-nav {
  min-height: 800px;
}

.news-nav-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-nav .story {
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 150px;
}

.tournaments-panel .events-row {
  display: block;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 40px;
}

.news-nav .story .draft,
.tournaments-panel .events-row .draft {
  position: absolute;
  z-index: 2;
  top: 15px;
  right: -25px;
  transform: rotate(45deg);
  display: inline-block;
  padding: 3px 30px;
  background-color: #625f48;
  color: #000;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  line-height: 1em;
}

.news-nav .story a,
.news-nav .story .news-anchor {
  display: block;
  padding: 20px;
}

.news-nav .story .news-viewer a {
  display: inline-flex;
  padding: 0;
  text-transform: none;
}

.news-nav .story .news-viewer ul {
  color: #787878;
  font-family: 'Bender Bold';
}

.news-nav .story a .news-thumb {
  width: 100%;
  padding-top: 25%;
  margin-bottom: 20px;
  background: url(../img/news-thumb.jpg) no-repeat center center;
  background-size: cover;
  margin-right: 20px;
  border: 4px solid #1e1d18;
}

.news-nav .story a .news-text {
  width: 75%;
}

.news-nav .story .news-image {
  padding: 20px 15px 0;
}

.news-nav .story .news-image-inner {
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 4px solid #1e1d18;
}

.input-news-image.MuiFormControl-root {
  padding-bottom: 20px;
}

.news-nav .story .title,
.news-nav .story h1 {
  color: #fff;
  font-size: 1.2em;
  line-height: 1.1em;
  padding-bottom: 15px;
  margin: 0;
  text-transform: uppercase;
  display: block;
}

.news-nav .story .desc,
.news-nav .story p,
.news-nav .story div > .public-DraftStyleDefault-block {
  display: block;
  color: #787878;
  padding-bottom: 5px;
  text-transform: none;
  margin: 0;
  font-family: 'Bender Bold';
}

.news-nav .story .date {
  color: #716c4a;
  font-size: 0.9em;
  padding-top: 10px;
  display: block;
  text-transform: none;
}

.news-story .public-DraftEditor-content > div > div {
  padding-bottom: 8px;
}

.content-loader {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.news-comments .loader-centered {
  padding: 0 0 20px 0;
}

.rankings-modal .loader-centered {
  padding: 40px 0 20px;
}

.events .loader-centered {
  padding: 20px 0;
}

.news-comments {
  padding: 20px;
  background: #000;
}

.comment-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}

.story-comment {
  display: flex;
  align-items: center;
  padding: 20px 0;
  font-family: 'Bender Bold';
  color: #707070;
  border-bottom: 1px solid #1c1c1a;
}

.story-comments-posted,
.story-comment-number {
  color: #65624a;
}

.story-comment-number {
  padding-top: 5px;
}

.story-comment:first-child {
  padding: 0 0 20px 0;
}

.story-comment-left {
  width: 40px;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
}

.story-comment-left a {
  padding: 0 !important;
  display: block !important;
}

.story-comment-right {
  padding: 0 0 0 20px !important;
}

.story-comment-right a {
  padding: 0 !important;
  display: inline-block !important;
  text-transform: none !important;
  color: #fff !important;
}

.story-comment-number {
  text-align: center;
}

.story-comment .profile-image {
  width: 40px;
  height: auto;
}

.input-news-comment textarea {
  background: #000;
  border: 1px solid #222;
  padding: 15px;
  border-radius: 5px;
  color: #fff;
  font-family: 'Bender';
  font-size: 1.1em;
}

.rankings-rank {
  display: flex;
  justify-content: left;
  align-items: center;
}

.rankings-trophy {
  padding-right: 10px;
}

.rank-badge {
  padding-right: 15px;
  color: #fff;
  font-family: 'Khand', sans-serif;
  font-weight: 700;
  font-size: 1.2em;
}

.rank-badge sup {
  font-size: 0.55em;
}

.rank-details {
  flex-grow: 1;
}

.rank-details > span {
  display: block;
  font-size: 0.9em;
}

.rank-details .timer,
.rankings-table .timer,
.streamer-box-timer .timer {
  color: #625f48;
  font-size: 1.1em;
  padding: 3px 0;
}

.rank-details .timer.ended {
  color: #e41a1a;
}

.rank-details .timer .label,
.rankings-table .timer .label,
.streamer-box-timer .timer .label {
  color: #434130;
  font-size: 0.8em;
  padding-left: 2px;
  padding-right: 5px;
}

.rankings-table .timer {
  color: #707070;
}

.rankings-table .timer .label {
  color: #474747;
}

.rankings-table tr td {
  border-bottom: 2px solid #000;
}

.rank-details .points {
  color: #625f48;
  font-size: 1.1em;
  padding: 3px 0;
}

.rank-details .points .label {
  color: #434130;
  font-size: 0.8em;
}

.rank-details .cta {
  color: #7ab903;
}

.rank-details .username {
  color: #a9a9a9;
}

.rank-details .league {
  padding: 2px 0;
  font-family: 'Khand', sans-serif;
  font-weight: 900;
}

.main-nav li.twitch {
  background: url(../img/icon-twitch.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.review {
  background: url(../img/icon-vods.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.review.active {
  background: url(../img/icon-vods-green.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.dashboard {
  background: url(../img/icon-dashboard.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.dashboard.active {
  background-image: url(../img/icon-dashboard-green.png);
}

.main-nav li.admin {
  background: url(../img/icon-admin.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.admin.active {
  background-image: url(../img/icon-admin-green.png);
}

.main-nav li.trophy {
  background: url(../img/icon-trophy.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.tournaments {
  background: url(../img/icon-tournaments-grey.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.bounty {
  background: url(../img/icon-bounty-grey.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.bounty.active {
  background: url(../img/icon-bounty-green.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.trophy.active {
  background-image: url(../img/icon-trophy-green.png);
}

.main-nav li.leagues {
  background: url(../img/icon-leagues.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.leagues.active {
  background-image: url(../img/icon-leagues-green.png);
}

.main-nav li.events {
  background: url(../img/icon-events.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.events.active {
  background: url(../img/icon-events-green.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.premium {
  background: url(../img/icon-premium.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.premium.active {
  background: url(../img/icon-premium-green.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.teams {
  background: url(../img/icon-team.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.teams.active {
  background: url(../img/icon-team-green.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.news {
  background: url(../img/icon-news.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.news.active {
  background: url(../img/icon-news-green.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.guide {
  background: url(../img/icon-guide.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.guide.active {
  background: url(../img/icon-guide-green.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.donate {
  background: url(../img/icon-donate.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.donate.active {
  background: url(../img/icon-donate-green.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.community {
  background: url(../img/icon-community.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.goons {
  background: url(../img/icon-goons.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.main-nav li.goons.active {
  background-image: url(../img/icon-goons-green.png);
}

.main-nav li.logout {
  background: url(../img/icon-logout.png) no-repeat 15px center;
  background-size: 18px 18px;
}

.loader-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

nav .active a:hover,
nav .active a:visited {
  color: #7ab903;
}

.streams,
.events {
  background: rgba(19, 19, 17, 0.9);
}

.streams ul,
.events ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.streams li,
.events li {
  padding: 0;
}

.streams li {
  border-bottom: 1px solid #0a0a08;
}

.streams li:last-child {
  border-bottom: none;
}

.streams li.divider,
.events li.divider {
  padding: 0 15px;
}

.streams li.divider div,
.events li.divider div {
  border-bottom: 1px solid #1c1c1a;
}

.streams a,
.streams a:hover,
.featured-details a {
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Bender Bold';

  font-style: normal;
  font-size: 0.95em;
  line-height: 1em;
  color: #625f48;
  padding: 12px 15px;
}

.streams .twitch .username,
.featured-details .username {
  padding-left: 8px;
}

.streams .twitch .viewers,
.featured-details .viewers {
  flex-grow: 1;
  text-align: right;
  color: #6d6c63;
  font-size: 0.9em;
}

.streams .avatar,
.featured-details .avatar {
  width: 20px;
  height: auto;
  margin-right: 0;
}

.community-streams a {
  display: flex;
  align-items: center;
}

.community-streams .avatar {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

.main .all-link,
.main .all-link:hover {
  background: #100f0f;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Bender Bold';

  font-style: normal;
  text-align: center;
  display: block;
  border-top: 1px solid #000;
  padding: 8px 0 10px;
  font-size: 0.8em;
  color: #7ab903;
  line-height: 1em;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.main .dual-link {
  background: #100f0f;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Bender Bold';

  font-style: normal;
  text-align: center;
  display: block;
  border-top: 1px solid #000;
  padding: 8px 0 10px;
  font-size: 0.8em;
  line-height: 1em;
  color: #474842;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.main .dual-link a,
.main .dual-link a:hover {
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Bender Bold';

  font-style: normal;
  text-align: center;
  color: #7ab903;
}

.sponsors .title,
.active-events .title,
.upcoming-events .title {
  display: block;
  background: #000;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Bender Bold';

  font-style: normal;
  font-size: 0.8em;
  line-height: 1em;
  padding: 5px 0;
}

.active-events .title,
.upcoming-events .title {
  text-align: left;
  padding-left: 15px;
}

.sponsors .logos {
  background-color: rgba(9, 9, 7, 0.9);
  text-align: center;
}

.sponsors .logos img {
  width: 100%;
  height: auto;
}

.aside-competitors,
.aside-goons,
.aside-kickcom,
.aside-leagues,
.aside-rankings,
.aside-events,
.aside-modules,
.aside-teams,
.aside-subscription-status,
.aside-roles,
.aside-tournaments {
  margin-top: 15px;
}

.badge-inner {
  inset: 4px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
}

.badge-outer {
  aspect-ratio: 1;
  inline-size: 100%;
  position: relative;
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
}

.badge-outer .badge-rank {
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
}

.badge-outer .badge-rank span {
  display: inline-block;
  line-height: 1em;
  padding-top: 2px;
  text-shadow: 0 0 1px #000, 0 0 2px #000;
}

.badge-32 {
  min-width: 32px;
}

.badge-48 {
  min-width: 48px;
}

.badge-red .badge-inner {
  background-color: #e41a1a;
}

.badge-red.badge-outer {
  background-color: #6f1313;
}

.badge-violet .badge-inner {
  background-color: #aa36cb;
}

.badge-violet.badge-outer {
  background-color: #530f66;
}

.badge-blue .badge-inner {
  background-color: #369abe;
}

.badge-blue.badge-outer {
  background-color: #0d4154;
}

.badge-green .badge-inner {
  background-color: #5dba5f;
}

.badge-green.badge-outer {
  background-color: #125613;
}

.badge-yellow .badge-inner {
  background-color: #e7c212;
}

.badge-yellow.badge-outer {
  background-color: #736210;
}

.badge-grey .badge-inner {
  background-color: #707070;
}

.badge-grey.badge-outer {
  background-color: #585858;
}

.badge-brown .badge-inner {
  background-color: #625f48;
}

.badge-brown.badge-outer {
  background-color: #3d3b2e;
}

.middle-content {
  padding: 0 15px;
}

.content-expanded {
  padding: 0;
}

.banner {
  background: #000 url(../img/banner-1-bg.jpg) no-repeat center right;
  height: 161px;
  border-radius: 5px;
}

.banner-goons {
  background: #000 url(../img/banner-goons-bg.jpg) no-repeat center right;
  border: 3px solid #222;
  display: block;
  text-decoration: none !important;
  height: auto;
  min-height: 161px;
}

.banner-goons .banner-text {
  padding-right: 125px;
}

.banner-goons .desc {
  color: #fff;
  text-transform: none !important;
}

.banner-text {
  padding: 20px 255px 20px 20px;
  line-height: 1em;
  font-size: 1em;
}

.banner-text > span {
  display: block;
}

.teams-list {
  padding: 15px;
}

.teams-list-team {
  background-color: #080808;
  margin-bottom: 15px;
}

.teams-list-team:last-child {
  margin-bottom: 0;
}

.teams-list-team-name {
  background-color: #333;
  color: #000;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  text-align: center;
  display: block;
  line-height: 1em;
  padding: 5px 15px;
}

.teams-list-team-members {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px 0;
}

.teams-list-team-member {
  line-height: 1em;
  display: inline-block;
  padding: 5px 20px;
}

.banner .btn-primary {
  background: #7ab903;
  color: #000 !important;
  padding: 8px 20px;
  border: none;
  border-radius: 3px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  font-style: normal;
  font-size: 1em;
  line-height: 1em;
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
}

.spot-goons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background: #3f3d2f;
  margin-top: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.spot-goons span {
  text-transform: uppercase;
  font-family: 'Bender Bold';
  color: #000;
}

.banner-text .title {
  text-transform: uppercase;
  font-family: 'Bender Bold';
  line-height: 1em;
  font-style: normal;
  color: #7ab903;
  font-size: 1.3em;
  padding-bottom: 10px;
}

.banner-text .desc {
  text-transform: uppercase;
  font-family: 'Bender Bold';
  line-height: 1em;
  font-style: normal;
  line-height: 1.25em;
}

.goons-map {
  position: relative;
  z-index: 1;
  min-height: 132px;
  background: rgba(19, 19, 17, 0.9);
}

.map-customs .goons-map-img {
  background: url(../img/goons-map-customs.jpg) no-repeat bottom center;
}

.map-lighthouse .goons-map-img {
  background: url(../img/goons-map-lighthouse.jpg) no-repeat bottom center;
}

.map-shoreline .goons-map-img {
  background: url(../img/goons-map-shoreline.jpg) no-repeat bottom center;
}

.map-woods .goons-map-img {
  background: url(../img/goons-map-woods.jpg) no-repeat bottom center;
}

.goons-map-img {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  min-height: 132px;
  background-size: cover !important;
  opacity: 0.3;
}

.goons-map-lines {
  background: url(../img/goons-map-lines.png) no-repeat bottom center;
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  min-height: 132px;
  background-size: cover !important;
  opacity: 0.3;
}

.goons-map-inner {
  min-height: 132px;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 4;
  text-decoration: none !important;
}

.goons-map-inner > span {
  padding: 0 15px 0 47px;
}
.goons-map-inner > span > span {
  display: block;
}

.goons-map-inner .title {
  display: inline-block;
  text-transform: uppercase;
  font-family: 'Bender Bold';

  font-style: normal;
  line-height: 1em;
  font-size: 1.5em;
  color: #fff;
}

.goons-map-inner .title.verified {
  background: url(../img/icon-verified-green.png) no-repeat right center;
  padding-right: 30px;
}

.goons-map-inner .spotter {
  text-transform: uppercase;
  color: #fff;
  font-family: 'Khand', sans-serif;
  font-weight: 700;
  line-height: 1em;
  padding-top: 8px;
  color: #a9a9a9;
  font-size: 1em;
}

.goons-map-inner .time {
  text-transform: uppercase;
  font-family: 'Bender Bold';
  font-style: normal;
  color: #7ab903;
  line-height: 1em;
  padding-top: 3px;
  font-size: 0.9em;
}

div.events a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  padding: 15px;
}

.events .calendar,
.events-row .calendar {
  width: 70px;
  background: #080808;
  padding: 10px 0;
  margin-right: 15px;
}

.sidebar-events-item .calendar {
  width: 60px;
  min-width: 60px;
}
.sidebar-events-item {
  border-bottom: 1px solid #000;
}

.events-row .calendar {
  width: 80px;
}

.events-row .calendar {
  margin-right: 0;
}

.events .calendar span,
.events-row .calendar span {
  display: block;
  text-align: center;
}

.events .calendar .label,
.events-row .calendar .label,
.events .calendar .date,
.events-row .calendar .date {
  color: #474842;
  font-family: 'Khand', sans-serif;
  font-weight: 700;
  line-height: 1em;
  font-size: 0.95em;
  text-transform: uppercase;
}

.events .calendar .day,
.events-row .calendar .day {
  text-transform: uppercase;
  font-family: 'Bender Bold';
  font-style: normal;
  color: #8a8a88;
  line-height: 1em;
  font-size: 1.5em;
  padding-bottom: 2px;
}

.events-row .calendar .day {
  font-size: 1.75em;
}

.events .event-details {
  background: url(../img/icon-clickthrough.png) no-repeat center right;
  flex-grow: 1;
}

.events .event-details span {
  display: block;
}

.events .event-details .title {
  background: none;
  padding: 0;
  font-size: 1.1em;
}

.sidebar-events-item .title {
  font-size: 0.9em !important;
  padding-right: 15px !important;
}

.events .event-details .desc,
.events .event-details .desc2 {
  font-family: 'Khand', sans-serif;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
}

.events .event-details .desc {
  color: #625f48;
  padding-top: 10px;
}

.sidebar-events-item .desc {
  padding-top: 8px !important;
  font-size: 0.9em !important;
}

.events .event-details .desc2 {
  color: #4e4e4e;
  padding-top: 3px;
  font-size: 0.95em;
}

.sidebar-events-item .desc2 {
  display: flex !important;
  align-items: center !important;
  font-size: 0.9em !important;
}

.footer-container {
  margin-top: 20px;
}

footer {
  text-transform: uppercase;
  font-family: 'Bender';
  font-style: normal;
  color: #292929;
  line-height: 1.5em;
  font-size: 0.8em;
  text-align: center;
}

footer .social-links {
  justify-content: center;
  margin-bottom: 20px;
}

footer .social-link,
.social-links .divider {
  background-color: #15181e;
}

footer .copyright,
footer .affiliated {
  display: block;
}

footer .affiliated {
  font-size: 0.9em;
  color: #3f3d2f;
}

footer .logo {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer .logo img {
  width: 38px;
  height: 43px;
}

footer .logo span {
  color: #7ab903;
  font-family: 'Bender Bold';
  font-size: 2em;
  display: inline-block;
  padding-left: 15px;
}

.footer-links {
  padding-bottom: 15px;
}

.footer-links a,
.footer-links a:hover,
.footer-links a:active,
.footer-links a:visited {
  color: #fff;
  text-decoration: none;
  margin-right: 10px;
  font-size: 0.9em;
}

.main-container .social-links {
  display: none;
}

.middle-content {
  padding: 0;
}

.banner-text {
  padding: 20px;
}

.btn-login,
.desktop {
  display: none !important;
}

.overlay-banner-login .btn-login,
.middle-content .btn-login {
  display: inline-block !important;
}

.main {
  display: flex;
  flex-direction: column;
}

.sidebar-left {
  order: 2;
}

.middle-content {
  order: 1;
}

.sidebar-right {
  order: 3;
}

.aside-streams {
  margin-top: 15px;
}

.middle-content .aside-streams {
  margin-top: 15px;
}

.btn-menu,
.btn-menu-close {
  cursor: pointer;
}

.btn-menu img,
.btn-menu-close img {
  width: 46px;
  height: auto;
}

.mobile-menu {
  align-items: center;
  /* clip-path: polygon(93% 2%, 97% 2%, 97% 4%, 93% 4%); */
  color: #f2f2f2;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  font-size: 28px;
  height: 100%;
  position: fixed;
  z-index: 9999;
  /* opacity: 0; */
  background-color: rgba(9, 9, 7, 1);
  clip-path: polygon(101% -1%, 101% 101%, -1% 101%, -1% -1%);
  top: 0;
  /* transition: clip-path 400ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 400ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 400ms cubic-bezier(0.4, 0, 0.2, 1); */
  width: 100%;
}

.mobile-menu .footer-container {
  background-color: transparent;
}

.mobile-menu a.logo {
  background-position: 15px center !important;
}

.menu-active {
  overflow: hidden;
}

.menu-active .mobile-menu {
  background-color: rgba(9, 9, 7, 1);
  clip-path: polygon(101% -1%, 101% 101%, -1% 101%, -1% -1%);
  z-index: 999;
  opacity: 1;
}

.mobile-menu .main-nav {
  background: transparent;
  width: calc(100% - 40px);
  margin: 25px 0;
}

.mobile-menu .main-nav li.divider div {
  border-color: #292929;
}

.mobile-menu .main-nav a,
.mobile-menu .main-nav a:hover {
  font-size: 0.8em;
  padding: 15px 0 15px 47px;
}

.mobile-menu a.logo {
  padding-left: 65px;
  background-position: center center;
}

.mobile-menu .footer-links {
  line-height: 0.85em;
}

.mobile-menu .footer-links a {
  font-size: 0.6em;
  color: #707070;
}
.mobile-menu .copyright,
.mobile-menu .affiliated {
  font-size: 0.6em;
  line-height: 1.5em;
}

.mobile-menu .sidebar-nav-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mobile-menu .aside-menu {
  display: none;
}

.mobile-menu footer .logo {
  display: none;
}

.avatar {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  font-style: normal;
  margin-right: 15px;
}

.avatar .avatar-image {
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar .avatar-image img {
  width: 40px;
  height: auto;
}

.avatar span {
  padding-left: 15px;
  display: none;
}

.middle-content .content-panel,
.content-expanded .content-panel {
  padding: 18px;
  background: rgba(9, 9, 7, 0.9);
  font-family: 'Bender';
  font-style: normal;
}

.news-loader {
  padding: 18px;
  background: rgba(9, 9, 7, 0.9);
}

.middle-content .content-panel a,
.content-expanded .content-panel a {
  color: #7ab903;
}

.middle-content .content-panel .heading-text,
.content-expanded .content-panel .heading-text {
  font-family: 'Bender Bold';
  font-size: 1.1em;
  padding-bottom: 10px;
  display: block;
  color: #7ab903;
}

.heading-value {
  color: #707070;
}

.form-control {
  display: flex !important;
  justify-content: right !important;
  flex-direction: row !important;
}

.MuiButton-contained {
  font-family: 'Bender Bold' !important;
  font-style: normal !important;
}

.paragraph {
  display: block;
  padding-bottom: 15px;
}

.subhead {
  display: block;
  text-transform: uppercase;
  font-family: 'Bender Bold' !important;
  padding-bottom: 15px;
  color: #fff;
}

.disclaimer {
  font-size: 0.9em;
  color: #625f48;
}

.MuiAlert-standardSuccess {
  background: #7ab903 !important;
}

.MuiAlert-standardError {
  background: #b90325 !important;
}

.MuiAlert-standardInfo {
  background: #434130 !important;
}

.MuiAlert-standardSuccess .MuiAlert-icon,
.MuiAlert-standardError .MuiAlert-icon,
.MuiAlert-standardInfo .MuiAlert-icon {
  color: #000 !important;
}

.MuiAlert-standardSuccess .MuiAlert-message,
.MuiAlert-standardError .MuiAlert-message,
.MuiAlert-standardInfo .MuiAlert-message {
  color: #000 !important;
  font-family: 'Bender Bold' !important;
  font-style: normal !important;
  text-transform: uppercase !important;
  font-size: 1.1em !important;
}

.form-alerts .MuiAlert-standardSuccess,
.form-alerts .MuiAlert-standardError {
  margin: 10px 0;
}

.league-runs .MuiAlert-standardError {
  margin-bottom: 20px;
}

table .MuiTableCell-root {
  font-family: 'Bender' !important;
  font-style: normal !important;
  text-transform: uppercase !important;
}

table .MuiTableCell-head {
  font-family: 'Bender Bold' !important;
  color: #625f48 !important;
  font-size: 1.05em !important;
}

.chatbot-settings {
  padding: 15px 0 0;
}

.twitch-chat-wrapper {
  padding: 10px;
  text-align: center;
  background: rgba(19, 19, 17, 0.9);
  border: 1px solid #716c4a;
  margin-bottom: 20px;
}

.twitch-chat-wrapper span {
  text-transform: uppercase;
  display: block;
  padding: 5px 0 15px;
  color: #fff;
  font-family: 'Bender Bold' !important;
}

.twitch-chat {
  width: 100%;
  height: 400px;
  border: 1px solid #716c4a;
}

.content-alert {
  margin-bottom: 15px;
}

.helper-message {
  text-align: center;
  padding-bottom: 15px;
}

.helper-message img {
  margin: 0 auto;
  border: 1px solid #707070;
  max-width: 100%;
  height: auto;
}

.chatbot-controls {
  display: flex;
  justify-content: center !important;
  background-color: rgba(19, 19, 17, 0.9);
  padding: 25px !important;
}

.overlay-controls {
  background-color: rgba(19, 19, 17, 0.9);
  padding: 25px !important;
  display: block !important;
}

.btn-disconnect {
  margin-right: 15px !important;
  background-color: #e41a1a !important;
}

span.green {
  color: #7ab903 !important;
}

.MuiFormControl-root {
  display: flex !important;
}

.controls-group {
  background: rgba(19, 19, 17, 0.9);
  padding: 15px 25px;
  margin-bottom: 30px;
}

.controls-group .MuiFormControl-root {
  margin-bottom: 15px !important;
}

.controls-group .MuiFormControl-root:last-child {
  margin-bottom: 0 !important;
}

.overlay {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
}

.overlay-align-left {
  left: -400px;
  right: auto;
}

.overlay-align-right {
  right: -400px;
  left: auto;
}

.overlay-goons,
.overlay-league-run {
  display: flex;
  max-width: 500px;
  border-top: 5px solid #7ab903;
  border-bottom: 10px solid #65624a;
  box-shadow: 0 0 25px #000;
  opacity: 0;
  transition: all 2000ms;
}

.overlay-badge {
  background: #0a0a08 url(../img/overlay-badge-bg.jpg) repeat-x center bottom;
  color: #7ab903;
  text-align: center;
  padding: 10px;
  border-right: 3px solid #35342b;
  transition: all 1000ms ease-in 500ms;
  opacity: 0;
}

.overlay-align-left .overlay-badge {
  order: 2;
  border-left: 3px solid #35342b;
  border-right: none;
}

.overlay-badge img {
  width: 50px;
  height: auto;
  margin-bottom: 5px;
}

.overlay-league-run .overlay-badge img {
  margin-bottom: 0;
}

.overlay-badge span {
  display: block;
  font-size: 0.9em;
}

.overlay-goons-map,
.overlay-league-map {
  width: 320px;
  background: url(../img/goons-map-customs.jpg) no-repeat center center;
  background-size: cover;
  position: relative;
  z-index: 11;
  overflow: hidden;
}

.overlay-league-map {
  width: 320px;
  background-position: center right;
  background-size: contain;
  position: relative;
  z-index: 11;
  overflow: hidden;
}

.overlay-align-left .overlay-goons-map,
.overlay-align-left .overlay-league-map {
  order: 1;
}

.overlay-goons-map-inner,
.overlay-league-map-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 13;
  background: url(../img/goons-map-lines.png) repeat-x center bottom;
  background-size: 80% auto;
}

.overlay-league-map-inner {
  opacity: 0.5;
}

.overlay-league-map-content {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 12;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background: linear-gradient(
    90deg,
    rgba(18, 18, 13, 1) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgba(18, 18, 13, 0) 100%
  );
}

.overlay-goons-map-goons {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 12;
  top: 200px;
  left: 0;
  background: url(../img/goons.png) no-repeat 95px bottom;
  background-size: 40% auto;
  transition: all 1000ms ease-in 500ms;
}

.overlay-goons-timeago {
  position: absolute;
  z-index: 15;
  bottom: -40px;
  right: 0;
  height: 30px;
  width: 340px;
  background: #65624a;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 8% 100%);
}

.overlay-banner .overlay-goons-timeago {
  bottom: -39px;
}

.overlay-align-left .overlay-goons-timeago {
  clip-path: polygon(0% 0%, 100% 0%, 92% 100%, 0% 100%);
  left: 0;
  right: auto;
}

.overlay-goons-timeago > span {
  background: #1e1d18;
  padding: 3px 0 2px;
  display: inline-block;
  border-radius: 3px;
  font-family: 'Khand', sans-serif;
  font-weight: 700;
  font-size: 0.9em;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1em;
  color: #817b5f;
  margin-left: 400px;
  width: 107px;
  text-align: center;
  transition: all 1000ms ease-in 500ms;
}

.overlay-league-run .overlay-goons-timeago > span {
  background: none;
  padding: 0;
  display: inline-block;
  border-radius: none;
  font-family: 'Khand', sans-serif;
  font-weight: 700;
  font-size: 0.9em;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1em;
  color: #817b5f;
  width: auto;
}

.overlay-align-left .overlay-goons-timeago span {
  margin-left: -150px;
}

.overlay-league-run .overlay-goons-timeago > span.overlay-league-points {
  display: flex;
  align-items: center;
  color: #1e1d18;
  font-size: 1.4em;
  line-height: 1em;
  padding-left: 8px;
}

.overlay-league-run .overlay-goons-timeago span.overlay-league-points-label {
  font-size: 0.6em;
  color: #a8a282;
  background: #1e1d18;
  padding: 4px 5px 2px;
  margin-left: 5px;
  margin-top: -2px;
  border-radius: 3px;
  line-height: 1em;
}

.overlay-goons-textbox {
  position: absolute;
  z-index: 15;
  bottom: 50px;
  right: 0;
  height: 40px;
  width: 185px;
  background: #000 url(../img/overlay-badge-bg.jpg) repeat-x 0 -60px;
  border-bottom: 5px solid #7ab903;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1000ms ease-in 500ms;
}

.overlay-align-left .overlay-goons-textbox {
  left: 0;
  right: auto;
}

.overlay-goons-textbox span {
  font-family: 'Bender Bold';
  color: #989898;
  font-size: 1.2em;
}

.overlay-league-run .overlay-goons-textbox span {
  font-size: 1.4em;
}

.overlay-league-run .overlay-goons-textbox span .label {
  font-size: 0.8em;
  padding-left: 2px;
  padding-right: 5px;
  color: #6d6c63;
}

.overlay-goons.animate-overlay-goons-enter-done,
.overlay-rank.animate-overlay-rank-enter-done,
.overlay-notification.animate-overlay-notification-enter-done {
  opacity: 1;
}

.overlay-goons.animate-overlay-goons-exit-done,
.overlay-rank.animate-overlay-rank-exit-done,
.overlay-notification.animate-overlay-notification-exit-done {
  opacity: 0;
}

.overlay-goons.animate-overlay-goons-enter-done .overlay-goons-textbox {
  bottom: -50px;
}

.overlay-goons.animate-overlay-goons-exit-done .overlay-goons-textbox {
  bottom: 50px;
}

.overlay-goons.overlay-align-right.animate-overlay-goons-enter-done,
.overlay-rank.overlay-rank-right.animate-overlay-rank-enter-done,
.overlay-notification.overlay-align-right.animate-overlay-notification-enter-done {
  right: 0;
  left: auto;
}

.overlay-goons.overlay-align-right.animate-overlay-goons-exit-done,
.overlay-rank.overlay-rank-right.animate-overlay-rank-exit-done,
.overlay-notification.overlay-align-right.animate-overlay-notification-exit-done {
  right: -400px;
  left: auto;
}

.overlay-goons.overlay-align-left.animate-overlay-goons-enter-done,
.overlay-rank.overlay-rank-left.animate-overlay-rank-enter-done,
.overlay-notification.overlay-align-left.animate-overlay-notification-enter-done {
  right: auto;
  left: 0;
}

.overlay-goons.overlay-align-left.animate-overlay-goons-exit-done,
.overlay-rank.overlay-rank-left.animate-overlay-rank-exit-done,
.overlay-notification.overlay-align-left.animate-overlay-notification-exit-done {
  right: auto;
  left: -400px;
}

.overlay-goons.animate-overlay-goons-enter-done .overlay-goons-timeago > span {
  margin-left: 35px;
}

.overlay-goons.animate-overlay-goons-exit-done .overlay-goons-timeago span {
  margin-left: 400px;
}

.overlay-goons.overlay-align-left.animate-overlay-goons-enter-done
  .overlay-goons-timeago
  span {
  margin-left: 200px;
}

.overlay-goons.overlay-align-left.animate-overlay-goons-enter-done
  .overlay-goons-timeago
  span.overlay-league-points-label {
  margin-left: 10px;
}

.overlay-goons.overlay-align-left.animate-overlay-goons-exit-done
  .overlay-goons-timeago
  span {
  margin-left: -150px;
}

.overlay-goons.animate-overlay-goons-enter-done .overlay-goons-map-goons {
  top: 0;
}

.overlay-goons.animate-overlay-goons-exit-done .overlay-goons-map-goons {
  top: 200px;
}

.overlay-goons.animate-overlay-goons-enter-done .overlay-badge,
.overlay-notification.animate-overlay-notification-enter-done .overlay-badge {
  opacity: 1;
}

.overlay-goons.animate-overlay-goons-exit-done .overlay-badge,
.overlay-notification.animate-overlay-notification-exit-done .overlay-badge {
  opacity: 0;
}

.overlay-league-run .rank-badge {
  width: 64px;
}

.overlay-league-text > span {
  display: block;
}

.overlay-league-username {
  color: #cacac0;
}

.overlay-league-tier {
  font-family: 'Bender' !important;
  font-size: 0.9em;
  padding-top: 5px;
  text-transform: uppercase;
}

.overlay-league-run .badge-outer .badge-rank span {
  font-size: 1.4em;
}

.settings-alert-success {
  margin-bottom: 20px;
}

.rankings-tab,
.event-tab {
  background: #000 url(../img/aside-header-bg.jpg) repeat-x bottom left !important;
  border-bottom: 1px solid #000 !important;
  width: 33% !important;
  font-family: 'Bender Bold' !important;
  text-transform: uppercase !important;
  font-size: 1em !important;
}

.event-tab {
  width: 25% !important;
}

.event-challenge-tab {
  width: 50% !important;
}

.tiers-tabs {
  background: rgba(4, 4, 3, 0.9);
  border-top: 5px solid #000;
  padding: 5px 15px 0;
}

.tiers-tabs .MuiTabs-flexContainer {
  justify-content: space-between;
}

.tiers-tab {
  font-family: 'Bender' !important;
  padding: 16px 12px 21px !important;
}

.tiers-tab .tier-label {
  display: inline-block;
  border: 1px solid #7ab903;
  padding: 1px 8px 1px;
  border-radius: 15px;
  font-size: 0.88em;
}

.tier-label.tier-yellow {
  color: #e7c212;
  border: 1px solid #e7c212;
}

.tier-label.tier-green {
  color: #5dba5f;
  border: 1px solid #5dba5f;
}

.tier-label.tier-blue {
  color: #369abe;
  border: 1px solid #369abe;
}

.tier-label.tier-violet {
  color: #aa36cb;
  border: 1px solid #aa36cb;
}

.tier-label.tier-red {
  color: #e41a1a;
  border: 1px solid #e41a1a;
}

.league-yellow {
  color: #e7c212 !important;
}

.league-green {
  color: #5dba5f !important;
}

.league-blue {
  color: #369abe !important;
}

.league-violet {
  color: #aa36cb !important;
}

.league-red {
  color: #e41a1a !important;
}

.tier-content {
  padding: 15px;
  background: rgba(21, 21, 21, 0.9);
}

.league-week-banner {
  border-radius: 10px;
  overflow: hidden;
}

.league-week-banner {
  background: url(../img/goons-map-customs.jpg) no-repeat center center;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.league-week-banner.league-week-customs,
.task-map-customs {
  background-image: url(../img/goons-map-customs.jpg);
}

.league-week-banner.league-week-lighthouse,
.task-map-lighthouse {
  background-image: url(../img/goons-map-lighthouse.jpg);
}

.league-week-banner.league-week-shoreline,
.task-map-shoreline {
  background-image: url(../img/goons-map-shoreline.jpg);
}

.league-week-banner.league-week-woods,
.task-map-woods {
  background-image: url(../img/goons-map-woods.jpg);
}

.league-week-banner.league-week-interchange,
.task-map-interchange {
  background-image: url(../img/goons-map-interchange.jpg);
}

.league-week-banner.league-week-factory,
.task-map-factory {
  background-image: url(../img/goons-map-factory.jpg);
}

.league-week-banner.league-week-streets,
.task-map-streets {
  background-image: url(../img/goons-map-streets.jpg);
}

.league-week-banner.league-week-reserve,
.task-map-reserve {
  background-image: url(../img/goons-map-reserve.jpg);
}

.league-week-banner-gradient {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.92) 0%,
    rgba(0, 0, 0, 0.9) 50%,
    rgba(0, 0, 0, 0.75) 70%,
    rgba(0, 0, 0, 0.2) 100%
  );
}

.league-week-banner-text {
  position: relative;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px 20px;
}

.league-week-banner-text > span {
  display: block;
  font-family: 'Bender Bold';
  text-transform: uppercase;
}

.league-week-banner-text .title {
  padding-bottom: 5px;
  font-size: 1.1em;
}

.league-week-banner-text .duration,
.league-week-banner-text .items,
.league-week-banner-text .ends {
  max-width: 430px;
  color: #7ab903;
}

.league-week-banner-text .duration-value,
.league-week-banner-text .items-value {
  color: #989898;
  font-family: 'Bender';
  text-transform: none;
}

.league-week-banner-text .ends-value {
  color: #e41a1a;
}

.league-prizes {
  padding: 15px 0 10px;
}

.league-prizes-cta {
  padding: 20px 0 10px;
}

.league-prizes-cta .btn-primary {
  width: 100%;
}

.prizes-title {
  display: block;
  font-family: 'Bender Bold';
  text-transform: uppercase;
  padding-bottom: 5px;
}
.prizes-title.tier-yellow {
  color: #e7c212;
}

.league-prizes-placement {
  display: flex;
  justify-content: left;
  align-items: center;
}

.league-prizes-place {
  font-family: 'Bender';
  text-transform: uppercase;
  color: #625f48;
  font-size: 1.25em;
  display: inline-block;
  padding-left: 25px;
  padding-right: 35px;
}

.league-prizes-place.place-first,
.top-vodder {
  background-image: url(../img/icon-trophy-gold.png);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 18px;
}

.league-prizes-place.place-second,
.top-vodder.place-second {
  background-image: url(../img/icon-trophy-silver.png);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 18px;
}

.league-prizes-place.place-third,
.top-vodder.place-third {
  background-image: url(../img/icon-trophy-bronze.png);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 18px;
}

.disclaimer {
  font-family: 'Bender';
  display: block;
  font-size: 0.8em;
  color: #707070;
  padding-bottom: 10px;
}

.rankings-placement-trophy {
  width: 18px;
  height: auto;
}

.rankings-table .rank-badge {
  padding-right: 0;
}

.league-label {
  position: relative;
  z-index: 1;
}

.league-locked {
  position: absolute;
  z-index: 3;
  top: -1px;
  right: -14px;
  width: 12px;
  height: 12px;
  background: url(../img/icon-lock.png) no-repeat center center;
  background-size: 12px;
}

.tier-locked {
  position: absolute;
  z-index: 3;
  top: 10px;
  right: 8px;
  width: 12px;
  height: 12px;
  background: url(../img/icon-lock.png) no-repeat center center;
  background-size: 12px;
}

.tier-green .tier-locked {
  background-image: url(../img/icon-lock-green.png);
}

.tier-blue .tier-locked {
  background-image: url(../img/icon-lock-blue.png);
}

.tier-violet .tier-locked {
  background-image: url(../img/icon-lock-violet.png);
}

.tier-red .tier-locked {
  background-image: url(../img/icon-lock-red.png);
}

.rankings-table .rankings-points {
  color: #625f48;
  font-family: 'Bender Bold' !important;
  font-size: 1.1em;
}

.rankings-table .rankings-points span {
  font-size: 0.6em;
}

.rankings-table-header {
  background-color: rgba(0, 0, 0, 0.95);
}

.rankings-table-header th {
  padding: 2px 16px;
  font-size: 0.9em !important;
  color: #707070 !important;
}

.ends-value .timer-label {
  display: inline-block;
  padding-left: 2px;
  padding-right: 5px;
  color: #9d1212;
  font-size: 0.9em;
}

.run-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(9, 9, 7, 0.95);
  border: 2px solid #7ab903;
  box-shadow: 0 0 24px #000;
  border-radius: 5px;
}

.modal-content {
  padding: 0 20px;
  font-family: 'Bender';
}

.modal-content ol {
  margin-top: 0;
}

.modal-controls {
  display: flex;
  justify-content: right;
  padding: 0 0 20px;
}

.modal-controls .btn:last-child {
  margin-left: 20px;
}

.modal-content .MuiAlert-standard {
  margin: 20px 0;
}

.league-run-banner-container,
.get-started-banner-container {
  background-color: rgba(101, 98, 74, 0.9);
  padding: 8px 0;
}

.get-started-banner-container {
  background-color: #7ab903;
}

.maintenance-banner-container {
  background-color: #9d1212;
  padding: 8px 0;
}

.league-run-banner-container .wrapper,
.get-started-banner-container .wrapper,
.maintenance-banner-container .wrapper {
  display: flex;
  align-items: center;
}

.maintenance-banner-header,
.league-run-banner-header,
.get-started-banner-header,
.league-run-banner-details,
.league-run-banner-timer {
  background-color: #1e1d18;
  display: inline-flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 3px;
  margin-right: 15px;
}

.get-started-banner-header {
  background-color: #2f4800;
}

.maintenance-banner-header {
  background-color: #390707;
}

.league-run-banner-header img,
.maintenance-banner-header img {
  width: 14px;
  height: auto;
  margin-right: 10px;
}

.league-run-banner-header span,
.get-started-banner-header span,
.maintenance-banner-header span,
.league-run-banner-details span,
.league-run-banner-timer span {
  line-height: 1em;
  font-family: 'Khand';
  font-weight: 900;
  color: #7ab903;
  text-transform: uppercase;
  font-size: 0.95em;
  padding: 1px 0 0;
  letter-spacing: 1.25px;
}

.maintenance-banner-header span {
  color: #9d1212;
}

.league-run-banner-details span {
  color: #95916d;
}

.league-run-banner-timer span {
  color: #707070;
  font-family: 'Bender Bold';
}

.league-run-banner-timer span {
  color: #a9a9a9;
  font-family: 'Bender Bold';
  font-size: 0.95em;
  letter-spacing: 0;
}

.league-run-banner-timer span.ended {
  color: #e41a1a;
}

.league-run-banner-timer span .timer-label {
  padding: 0 5px 0 3px;
  font-size: 0.8em;
  color: #707070;
}

.league-run-banner-cta {
  flex-grow: 1;
  display: inline-flex;
  justify-content: right;
}

.league-run-banner-cta .btn-cancel,
.league-run-banner-cta .btn-cancel:hover {
  padding: 5px 12px;
}

.no-badges {
  display: block;
  padding: 20px;
  font-family: 'Bender Bold';
  color: #707070;
  font-size: 0.9em;
}

.no-competitors {
  display: block;
  padding: 25px;
  font-family: 'Bender Bold';
  color: #625f48;
  font-size: 0.9em;
}
.league-run-banner-container .wrapper {
  flex-direction: column;
}
.league-run-banner-container .wrapper > span {
  margin-bottom: 5px;
}

.league-submission-form {
  padding-top: 10px;
  padding-bottom: 20px;
}

.league-submission-form .subhead {
  padding-bottom: 0;
}

.league-submission-form .MuiFormControl-root {
  padding-bottom: 15px;
}

.league-submission-form .MuiFormLabel-root .paragraph {
  font-family: 'Bender';
  padding-bottom: 15px;
  font-size: 0.95em;
}

.league-submission-form .btn-cancel {
  background-color: #999;
  margin-left: 20px;
}

.unread-vods {
  align-items: center !important;
  display: flex !important;
  position: static !important;
}

.unread-vods .MuiBadge-standard {
  position: static;
  display: inline-flex;
  transform: none;
  margin-left: 10px;
  font-family: 'Khand';
  font-weight: 900;
  font-size: 0.8em;
  background-color: #e41a1a;
  padding: 0;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  height: 20px;
  width: 20px;
  padding-top: 3px;
  border-radius: 50%;
}

.league-runs {
  padding-top: 20px;
}

.league-run {
  border: 1px solid #1c1c1a;
  background: #000 url(../img/aside-header-bg.jpg) repeat-x bottom center;
  cursor: pointer;
  margin-bottom: 15px;
}

.league-run-status {
  background: #707070;
}

.league-run-approved .league-run-status {
  background: #125613;
}

.league-run-denied .league-run-status {
  background: #a02626;
}

.league-run-details {
  font-family: 'Bender Bold';
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  text-transform: uppercase;
}

.league-run-details span {
  display: block;
}

.league-run-details .league-run-league {
  padding-left: 65px;
}

.league-run-details .league-run-league {
  background: url(../img/icon-run-pending.png) no-repeat center left;
  background-size: 50px auto;
}

.league-run-denied .league-run-details .league-run-league {
  background: url(../img/icon-run-denied.png) no-repeat center left;
  background-size: 50px auto;
}

.league-run-approved .league-run-details .league-run-league {
  background: url(../img/icon-run-approved.png) no-repeat center left;
  background-size: 50px auto;
}

.league-run-locked .league-run-details .league-run-league {
  background: url(../img/icon-run-locked.png) no-repeat center left;
  background-size: 50px auto;
}

.league-run-details .league-run-info {
  text-align: right;
}

.info-my-runs {
  display: flex;
  align-items: center;
}

.league-run-info-right {
  margin-left: 15px;
  cursor: pointer;
  padding: 5px;
}

.league-run-info-right img {
  width: 20px;
  height: auto;
}

.league-run-details .league-run-info .run-link {
  display: inline-block;
}

.league-run-details .league-run-info .run-link:last-child {
  margin-left: 15px;
}

.league-run-details .run-league {
  font-size: 1.2em;
}

.league-run-details .run-season {
  font-size: 0.9em;
}

.league-run-details .run-season.yellow {
  color: #e7c212;
}

.league-run-details .run-season {
  font-size: 0.9em;
}

.league-run-details .league-run-info {
  font-size: 0.9em;
}

.league-run-details .run-created {
  color: #707070;
}

.league-run-details .run-submitted-by {
  display: flex;
}

.league-run-details .run-submitted-by span {
  font-size: 1.25em;
  color: #fff;
}

.league-run-details .run-submitted-by .avatar {
  margin-right: 10px;
}

.league-run-details .run-controls {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.run-reason {
  padding: 0 20px 20px;
}

.run-reason > span {
  padding: 10px 20px;
  background: #241818;
  display: block;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  color: #b94744;
  font-size: 0.9em;
}

.run-reason > span span {
  color: #b49291;
}

.league-run-status > span {
  color: #000;
  font-family: 'Bender Bold';
  text-transform: uppercase;
  font-size: 0.9em;
  line-height: 1em;
  padding: 4px 20px 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.league-run-locked,
.league-run-approved,
.league-run-denied {
  cursor: default;
  position: relative;
  z-index: 1;
}

.league-run-approved,
.league-run-denied {
  cursor: pointer;
}

.my-league-runs .league-run-approved,
.my-league-runs .league-run-denied {
  cursor: default;
}

.run-submitted-by,
.comment-username {
  display: flex;
  align-items: center;
}

.run-submitted-by .avatar {
  width: 30px;
  height: auto;
}

.comment-username .avatar {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

.comment-username .username {
  color: #707070;
  font-family: 'Bender Bold';
  font-size: 0.9em;
}

.run-type {
  text-transform: uppercase;
}

.run-open {
  width: 18px;
  height: auto;
}

.run-locked {
  display: block;
  background: #4e4e4e;
  color: #000;
  padding: 3px 10px 0;
  width: calc(100% + 30px);
  text-transform: uppercase;
  font-family: 'Khand';
  letter-spacing: 1px;
  font-weight: 900;
  margin-left: -15px;
  margin-top: -15px;
  margin-bottom: 12px;
}

.league-run-approved .run-locked {
  background: #125613;
}

.league-run-denied .run-locked {
  background: #6f1313;
}

.run-locked-user,
.news-user a,
.news-user > div {
  display: inline-flex;
  align-items: center;
}

.run-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.run-locked-user {
  display: flex;
  justify-content: center;
}

.run-locked-reason {
  display: block;
  text-align: center;
  font-weight: 600;
  text-transform: none;
}

.run-locked-user .avatar,
.news-user .avatar {
  width: 20px;
  height: auto;
  margin-right: 5px;
}

.middle-content .content-panel .news-user a,
.news-user > div {
  color: #707070 !important;
  text-transform: none !important;
  padding: 5px 0 0 !important;
  font-size: 0.9em !important;
}

.news-story.news-nav .story h1 {
  padding-bottom: 0;
}

.news-story .title-viewer {
  padding-bottom: 0;
}

.league-runs .no-competitors {
  padding: 10px 0 20px;
}

.run-submit-details {
  border-radius: 10px;
  background: #1c1c1a;
  height: 100%;
}

.run-submit-user {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.run-submit-user .avatar {
  width: 30px;
  height: auto;
}

.preview-submit-user {
  padding-top: 20px;
}

.summary-points {
  display: inline-block;
  padding-left: 15px;
}

.run-submit-details a {
  color: #fff;
}

.league-submission-form .MuiFormHelperText-root {
  font-family: 'Bender Bold';
  font-size: 0.8em;
}

.form-progress {
  border: 1px solid #35342b;
  overflow: hidden;
  border-radius: 3px;
  margin: 5px 0 20px;
}

.form-progress-inner {
  height: 6px;
  background-color: #7ab903;
  width: 0;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
}

.ranking-controls {
  padding-bottom: 20px;
}

.news-controls {
  background-color: #080808;
  padding: 10px 15px;
  display: flex;
  justify-content: right;
}

.news-controls a.btn-primary {
  padding: 8px 20px;
}

.news-controls button.btn-primary {
  margin-left: 15px;
}

.news-buttons {
  padding: 20px 10px;
  display: flex;
  justify-content: right;
}

.news-buttons button {
  margin-left: 15px;
}

.hide-toolbar {
  display: none;
}

.news-viewer {
  padding: 20px 15px;
}

a .news-viewer {
  padding: 0;
}

.news-viewer .public-DraftStyleDefault-block {
  margin: 0;
}

.news-editor {
  min-height: 200px;
}

.title-editor {
  margin-bottom: 20px;
  min-height: auto;
}

.news-published {
  font-family: 'Bender Bold';
  padding-left: 15px;
}

.main .main-col-full.middle-content {
  width: 100%;
  padding: 0;
}

.is-live {
  padding-right: 28px;
  background: url(../img/icon-live.png) no-repeat center right;
  background-size: 22px auto;
}

.is-partner {
  padding-right: 28px;
  background: url(../img/icon-twitch-verified.png) no-repeat center right;
  background-size: 22px auto;
}

.rbc-toolbar {
  flex-direction: column;
}

.rbc-toolbar .rbc-toolbar-label {
  padding-top: 10px !important;
}

.rbc-toolbar button {
  color: #fff !important;
  border: 1px solid #625f48 !important;
  background-color: #1c1c1a !important;
  cursor: pointer !important;
  font-family: 'Bender Bold' !important;
  text-transform: uppercase !important;
}

.rbc-toolbar button:hover {
  background-color: #292929 !important;
  color: #7ab903 !important;
}

.rbc-toolbar-label {
  color: #7ab903 !important;
}

.rbc-header {
  color: #7ab903 !important;
  border-bottom: 1px solid #707070 !important;
}

.rbc-off-range-bg {
  background: #1c1c1a !important;
}

.rbc-today {
  background: #7ab903 !important;
}

.rbc-now .rbc-button-link {
  color: #000 !important;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #625f48 !important;
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #625f48 !important;
}

.rbc-header + .rbc-header {
  border-left: 1px solid #707070 !important;
}

.rbc-month-view {
  border: 1px solid #707070 !important;
}

.rbc-date-cell .rbc-button-link {
  font-family: 'Bender Bold' !important;
}

.rbc-event {
  background-color: #65624a !important;
  text-transform: uppercase !important;
  font-size: 0.9em !important;
  padding: 2px 5px 0px !important;
  font-family: 'Bender Bold' !important;
  color: #000 !important;
}

.event-stream {
  background-color: #9146ff !important;
}

.event-stream .event-title {
  padding-left: 22px;
  background: url(../img/icon-twitch-black.png) no-repeat center left;
  background-size: 16px;
}

.event-league .event-title {
  padding-left: 22px;
  background: url(../img/icon-leagues-black.png) no-repeat 3px 2px;
  background-size: 15px;
}

.MuiTooltip-tooltip {
  font-family: 'Bender Bold' !important;
  color: #707070 !important;
}

.MuiTooltip-tooltip .subhead {
  color: #7ab903 !important;
  font-size: 1.2em;
}

.MuiTooltip-tooltip .event-cta {
  color: #fff !important;
}

.MuiTooltip-tooltip a {
  color: #7ab903 !important;
}

.MuiTooltip-tooltip .event-description,
.MuiTooltip-tooltip .event-times {
  padding-top: 20px;
  padding-bottom: 10px;
}

.MuiTooltip-tooltip .event-times span {
  display: block;
}

.MuiTooltip-tooltip .event-times > span {
  color: #7ab903 !important;
  text-transform: uppercase !important;
}

.MuiTooltip-tooltip .event-times > span span {
  color: #707070 !important;
}

.overlay-banner {
  background: url(../img/tarkov-overlay-bg.jpg) no-repeat center center;
  background-size: cover;
  /* position: relative;
  padding-top: 34.74%;
  min-height: 400px; */
  border: 1px solid #292929;
  /* z-index: 1; */
  width: 100%;
  /* overflow: hidden; */
  margin-bottom: 20px;
}

.overlay-banner-text {
  /* position: absolute;
  z-index: 3;
  top: 0;
  left: 0; */
  width: calc(100% - 40px);
  text-align: center;
  margin: 20px;
  background: rgba(19, 19, 17, 0.9);
  padding: 20px;
}

.overlay-banner-text h1 {
  text-transform: uppercase;
  font-family: 'Bender Bold';
  font-weight: bold;
  color: #7ab903;
  margin: 0;
  font-size: 1.4em;
}

.overlay-banner-text h2 {
  color: #999;
  font-family: 'Bender';
  font-weight: normal;
  margin: 0;
  font-size: 1.1em;
}

.overlay-banner .overlay-league-run {
  display: none;
}

.overlay-banner .overlay-league-username {
  font-family: 'Bender Bold';
  line-height: 1em;
  font-size: 1.1em;
}

.overlay-banner .overlay-league-tier {
  font-family: 'Bender Bold';
  line-height: 1em;
  font-size: 1.1em;
}

.overlay-banner .overlay-league-username {
  display: flex;
  align-items: center;
}

.overlay-banner .overlay-league-username .avatar {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.overlay-banner .overlay-league-username .verified {
  display: inline-block;
  padding-right: 25px;
  background: url(../img/icon-twitch-verified.png) no-repeat center right;
  background-size: 18px;
}

.value-props {
  margin-bottom: 20px;
}

.value-prop {
  text-align: center;
}

.subscribe .value-prop {
  text-align: left;
}

.value-prop-inner {
  height: 100%;
  padding: 60px 20px;
  background: rgba(101, 98, 74, 0.1);
}

.subscribe .value-prop-inner {
  padding: 20px;
  display: flex;
  align-items: center;
}

.subscribe .value-prop-icon-outer {
  padding-right: 20px;
}

.value-prop-icon {
  background: #080808;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 auto;
}

.subscribe .value-prop-icon {
  width: 50px;
  height: 50px;
}

.value-prop-text {
  padding-top: 10px;
  color: #a9a9a9;
}

.subscribe .value-prop-text {
  padding-top: 0;
}

.value-prop-text .subhead,
.plan-inner .subhead {
  color: #7ab903;
  padding-bottom: 5px;
}

.value-prop-text .subhead {
  font-size: 1.3em;
  padding-top: 20px;
}

.subscribe .value-prop-text .subhead {
  font-size: 1em;
  padding-top: 0;
}

.value-prop-text .paragraph {
  font-size: 1.1em;
  padding: 0 40px;
}

.subscribe .value-prop-text .paragraph {
  font-size: 1em;
}

.plan-inner .subhead {
  text-align: center;
  font-size: 1.3em;
  color: #fff;
}

.value-prop-icon img {
  width: 50%;
  height: auto;
}

.join-fence {
  background: url(../img/compare-bg.jpg) no-repeat center center;
  background-size: cover;
  padding: 20px;
  border: 1px solid #35342b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.comparison-chart {
  background: url(../img/compare-bg.jpg) no-repeat center center;
  background-size: cover;
  padding: 20px 0 20px;
  border: 1px solid #35342b;
  margin-bottom: 20px;
}

.comparison-chart-inner {
  padding: 0 20px 20px;
}

.plan-free .plan-inner {
  background-color: rgba(43, 43, 43, 0.85);
  padding: 20px;
}

.plan-premium .plan-inner {
  background-color: rgba(101, 98, 74, 0.4);
  padding: 20px;
}

.plan-inner {
  text-align: center;
}

.plan-price {
  padding: 20px 0;
}

.plan-price span {
  display: inline-block;
  margin: 0 auto;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #707070;
  color: #000;
  font-family: 'Bender Bold';
  font-size: 1.5em;
}

.plan-premium .plan-price span {
  background-color: #817b5f;
}

.plan-item {
  display: block;
  padding: 15px 0;
  font-size: 1em;
  border-bottom: 1px solid #989898;
  text-transform: uppercase;
  font-family: 'Bender Bold';
}

.plan-free .plan-item {
  color: #cacac0;
  border-bottom: 3px solid rgba(19, 19, 17, 0.5);
}

.plan-premium .plan-item {
  color: #a8a282;
  border-bottom: 3px solid rgba(19, 19, 17, 0.5);
}

.plan-item:last-child {
  border: none;
}

.comparison-desc {
  padding: 0 20px;
  text-align: center;
}

.comparison-desc .subhead {
  color: #7ab903;
  font-size: 2em;
  padding-bottom: 5px;
}

.comparison-desc .paragraph {
  padding: 0 20px 25px;
  font-size: 1.2em;
}

.overlay-banner-login {
  padding-top: 20px;
}

.runs-archive {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.runs-archive .MuiBadge-root {
  margin-right: 20px;
}

.runs-archive .MuiChip-root {
  padding-right: 30px;
}

a.competitors-link {
  border-bottom: 2px solid #000;
}
a.competitors-link:last-child {
  border-bottom: none;
}

.featured-stream {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
  border-bottom: 4px solid #080808;
  padding-top: 10px;
}

.iframe-wrapper {
  width: calc(100% - 20px);
  margin: 0 auto;
}

.iframe-inner {
  width: 100%;
  padding-top: 56.25%;
  z-index: 1;
  position: relative;
}

.featured-stream iframe {
  border: none;
  margin: 0 auto;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.streamer-box a {
  text-decoration: none;
  display: block;
  padding-top: 56.25%;
  border: 1px solid #000;
  position: relative;
  z-index: 1;
}

.streamer-box-details {
  background: #000;
  padding: 10px;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  font-style: normal;
  font-size: 0.95em;
  line-height: 1em;
  color: #625f48;
}

.streamer-box-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.streamer-box-username {
  display: flex;
  align-items: center;
}

.streamer-box-username .avatar {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

.streamer-box-timer {
  padding: 2px 10px;
  text-align: right;
  font-size: 0.9em;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
}

.streamer-box-timer .timer {
  color: #707070;
  font-family: 'Bender Bold';
  font-size: 0.8em;
}

.streamer-box-timer .timer .label {
  color: #4e4e4e;
}

.streamer-box-viewers {
  color: #6d6c63;
}

.ranks-row {
  flex-wrap: wrap;
}

.rank-summary {
  width: 100%;
}

.rankings-points-inner {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: right;
}

.rankings-points-inner span {
  display: inline-block;
  padding-left: 5px;
}

.rankings-points-inner img {
  width: 16px;
  height: auto;
  margin-left: 10px;
}

.aside-header .summary-title {
  display: flex;
  align-items: center;
}

.aside-header .username {
  display: inline-flex;
  align-items: center;
  padding: 0 5px 0 0;
}

.aside-header .username .avatar {
  width: 24px;
  height: auto;
}

.rankings-modal .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.rankings-modal .header .username {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.rankings-modal .header .avatar {
  width: 80px;
  height: auto;
}

.rankings-modal .header a {
  text-decoration: none;
  color: #fff;
}

.rankings-modal .badge-label {
  display: block;
  text-align: center;
  color: #707070;
  text-transform: uppercase;
  font-family: 'Bender Bold';
}

.rankings-modal .rank-badge {
  padding-right: 0;
}

.rankings-modal .badge-outer .badge-rank span {
  font-size: 1.35em;
}

.rankings-modal .header .displayname {
  font-size: 1.5em;
  display: inline-block;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  line-height: 1em;
  padding-bottom: 5px;
}

.rankings-modal .header .joined {
  color: #707070;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  display: block;
  line-height: 1em;
  padding-bottom: 8px;
}

.rankings-modal .league-ranks {
  display: flex;
  align-items: center;
}

.rankings-modal .league-rank {
  margin-left: 20px;
}

.rankings-modal .league-rank:first-child {
  margin-left: 0;
}

.rankings-modal .title {
  text-transform: uppercase;
  font-family: 'Bender Bold';
  padding: 20px 0 10px;
  display: block;
  color: #7ab903;
}

.rankings-modal a.vod-link {
  color: #7ab903;
}

.rankings-modal a.vod-link:last-child {
  margin-left: 15px;
}

.rankings-modal .loader-centered {
  width: 100%;
  display: flex;
  align-items: center;
}

.rankings-modal .MuiTabs-root .MuiTab-root {
  text-transform: uppercase;
  font-family: 'Bender Bold';
  font-size: 1em;
  background: rgba(21, 21, 21, 0.9);
  color: #707070;
}

.rankings-modal .MuiTabs-root .Mui-selected {
  background: rgba(101, 98, 74, 0.9);
  color: #fff;
}

.rankings-modal .MuiAccordionSummary-root {
  text-transform: uppercase;
  font-family: 'Bender Bold';
}

.rankings-modal .MuiAccordionDetails-root .subhead {
  padding-bottom: 0;
}

.rankings-modal .MuiAccordionDetails-root .MuiGrid-container {
  margin-top: -30px;
}

.rankings-modal .MuiAccordionSummary-root.Mui-expanded {
  background: rgba(101, 98, 74, 0.3);
}

.rankings-modal .MuiPaper-root.Mui-expanded {
  margin: 0;
}

.rankings-modal .MuiAccordion-region {
  max-height: 200px;
  overflow-y: auto;
}

.rankings-modal .league-tabs {
  border-bottom: 10px solid rgba(101, 98, 74, 0.9);
}

.overall-stats {
  background: rgba(21, 21, 21, 0.9);
}

.overall-stats .stats-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  padding: 5px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.9);
}

.overall-stats .stats-label {
  color: #fff;
}

.overall-stats .stats-value {
  color: #707070;
}

.overall-stats .stats-row:last-child {
  border-bottom: none;
}

.overall-stats-title {
  display: block;
  padding: 4px 10px 3px;
  background: rgba(101, 98, 74, 0.9);
  line-height: 1em;
  font-size: 0.9em;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  color: #000;
}

.overall-stats-inner {
  padding: 10px 20px;
}

.reminder {
  color: #e41a1a;
  font-family: 'Bender Bold';
}

.reminder-img span {
  display: block;
}

.reminder-img img {
  width: 100%;
  height: auto;
  max-width: 400px;
}

.overlay-rank {
  position: absolute;
  z-index: 9;
  top: 100px;
  right: -400px;
  width: 300px;
  font-family: 'Bender Bold';
  opacity: 0;
  transition: all 2000ms;
}

.overlay-notification {
  position: absolute;
  z-index: 19;
  top: 60px;
  right: -400px;
  width: 300px;
  font-family: 'Bender Bold';
  opacity: 0;
  transition: all 2000ms;
}

.overlay-notification-inner {
  display: flex;
  align-items: center;
}

.overlay-rank .badge-outer .badge-rank span {
  text-shadow: 0 0 1px #000, 0 0 2px #000, 0 0 4px #000, 0 0 8px #000;
}

.overlay-rank.overlay-rank-left {
  left: 0;
  right: auto;
}

.overlay-rank.overlay-rank-right {
  left: auto;
  right: 0;
}

.overlay-rank-top {
  background-position: center center;
  background-size: 100% auto;
}

.overlay-rank-top-inner {
  text-align: center;
  max-height: 118px;
  background-image: url('../img/goons-map-lines.png');
  background-repeat: repeat-x;
  background-position: center bottom;
  background-size: 100% auto;
}

.overlay-rank-top-inner-inner {
  background: rgb(122, 185, 3);
  background: linear-gradient(
    0deg,
    rgba(122, 185, 3, 0.2) 0%,
    rgba(122, 185, 3, 0.75) 100%
  );
}

.overlay-rank-top-inner span {
  display: block;
  margin: 0 auto;
  padding: 25px 0 15px;
  font-size: 1.25em;
  text-shadow: 0 0 3px #7ab903;
  font-family: 'Bender Bold';
}

.overlay-rank-top-inner img {
  margin: 0 auto;
}

.overlay-league-rank-content {
  border-top: 6px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1c1c1a;
  padding: 30px 0 20px;
}

.overlay-league-rank-content .overlay-league-username {
  font-size: 1.1em;
}

.overlay-league-rank-content .badge-outer .badge-rank span {
  font-size: 1.2em;
}

.overlay-league-rank-season {
  background: #292929;
}

.overlay-league-rank-season .season-title {
  display: block;
  text-align: center;
  background: #000;
  color: #707070;
  padding: 3px 0;
}

.overlay-league-rank-season .season-points {
  text-align: center;
  padding: 15px 0;
}

.overlay-league-rank-season .season-points-label {
  display: block;
  text-align: center;
  color: #e7c212;
  padding-bottom: 8px;
  font-size: 0.9em;
}

.overlay-league-rank-season .season-points-value {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 1.8em;
}

.overlay-league-rank-season .season-data {
  padding: 20px 20px 20px 40px;
}

.overlay-league-rank-season .MuiGrid-container {
  background: #0a0a08;
  padding-bottom: 20px;
}

.overlay-league-rank-season .season-data-label {
  font-size: 0.8em;
  display: block;
  color: #7ab903;
  padding-bottom: 3px;
}

.overlay-league-rank-season .season-data-value {
  color: #707070;
  display: block;
}

.overlay-notification {
  border-left: 5px solid #7ab903;
}

.overlay-notification .overlay-message {
  padding-left: 20px;
  background-color: #000;
  color: #7ab903;
}

.overlay-notification.notification-denied {
  border-left: 5px solid #e41a1a;
}

.overlay-notification.notification-denied .overlay-message {
  color: #e41a1a;
}

.overlay-notification-inner {
  background-color: #000;
}

.overlay-notification .overlay-badge img {
  margin-bottom: 0;
}

.referred-info {
  padding-bottom: 20px;
}

.referred-info .subhead {
  padding-bottom: 0;
}

.referred-info .value {
  font-family: 'Bender Bold';
  color: #707070;
  font-size: 1.5em;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.badges {
  display: flex;
  justify-content: left;
  align-items: center;
}

.badges .badge {
  margin-right: 20px;
}

.badges .badge .badge-outer {
  min-width: 100px;
}

.badges .badge .badge-inner {
  inset: 6px;
}

.badges .badge .badge-outer .badge-rank span {
  display: block;
  font-size: 0.8em;
  line-height: 0.8em;
  text-transform: uppercase;
  color: #51450b;
  text-shadow: 0 0 1px #ffffff, 0 0 2px #fbf0b5;
  text-align: center;
}

.badges .badge .badge-outer .badge-rank {
  flex-direction: column;
}

.badges .badge .rank-badge a {
  display: block;
}

.badges .badge .badge-outer .badge-rank .badge-league {
  padding-top: 12px;
}

.badges .badge .badge-outer .badge-rank .badge-place {
  font-size: 2em;
  line-height: 1em;
  color: #fff;
  text-shadow: 0 0 1px #000, 0 0 2px #000, 0 0 5px #736210;
  margin-top: -10px;
}

.badges .badge .badge-outer .badge-rank sup {
  font-size: 0.45em;
}

.badges .badge .badge-outer .badge-rank .badge-season {
  margin-top: -5px;
}

.total-points {
  background: rgba(101, 98, 74, 0.2);
  padding: 5px 15px 7px;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  color: #707070;
  text-align: right;
  line-height: 1em;
}

.total-points span {
  color: #707070;
}

.is-premium {
  background: #7ab903;
  color: #000;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  font-size: 0.9em;
  padding: 2px 8px;
  border-radius: 5px;
}

.upgrade-premium {
  text-align: center;
}

.subscribe-buttons {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-top: 20px;
}

.card-box {
  position: relative;
  z-index: 1;
}

.cashapp {
  background: #333 url(../img/icon-cash-app.svg) no-repeat 22px 10px;
  background-size: 40px auto;
}

.card-bank {
  background: #333;
}

.card-visa {
  background: #333 url(../img/icon-visa.png) no-repeat 15px 10px;
  background-size: 60px auto;
}

.card-mastercard {
  background: #333 url(../img/icon-mastercard.png) no-repeat 15px 10px;
  background-size: 60px auto;
}

.card-amex {
  background: #333 url(../img/icon-amex.png) no-repeat 15px 10px;
  background-size: 60px auto;
}

.card-discover {
  background: #333 url(../img/icon-discover.png) no-repeat 15px 10px;
  background-size: 60px auto;
}

.card-box .card-selected {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  background: #7ab903;
  color: #000;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  font-size: 0.8em;
  line-height: 1em;
  display: inline-block;
  padding: 2px 10px 4px;
}

.card-box span {
  text-transform: uppercase;
  font-family: 'Bender Bold';
  display: block;
  font-size: 1em;
  line-height: 1em;
  color: #999;
}

.card-box .card-type {
  padding-left: 70px;
  font-size: 1.8em;
  color: #000;
}

.card-bank .card-type {
  padding-left: 0;
}

.card-box .card-number-label {
  color: #000;
  font-size: 1.1em;
  padding-top: 10px;
}

.card-box .card-number {
  font-size: 1.5em;
  color: #999;
  padding-top: 5px;
  padding-bottom: 5px;
}

.card-actions {
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
}

.manage-payments .MuiGrid-root {
  padding-top: 0;
  margin-top: 0;
}

.payment-actions {
  padding-top: 20px;
}

.sub-joined {
  text-transform: uppercase;
  font-family: 'Bender Bold';
  display: block;
  font-size: 1em;
  line-height: 1em;
  padding-bottom: 5px;
  color: #999;
}

.sub-joined-value {
  text-transform: none;
  color: #707070;
}

.sub-status {
  text-transform: uppercase;
  font-family: 'Bender Bold';
  display: block;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  line-height: 1em;
  padding-bottom: 15px;
}

.sub-status-label {
  display: inline-block;
  padding-right: 10px;
}

.sub-status-value {
  background: #7ab903;
  color: #000;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  font-size: 0.8em;
  line-height: 0.8em;
  display: inline-block;
  padding: 3px 10px 4px;
  border-radius: 5px;
}

.sub-status-inactive,
.sub-past-due {
  background: #d55753;
}

.response-avg {
  padding-top: 10px;
}

.response-time {
  display: block;
  color: #7ab903;
  text-transform: uppercase;
  font-family: 'Bender Bold';
}

.response-time-value {
  color: #707070;
}

.sub-disclaimer {
  padding-top: 5px;
  font-size: 0.9em;
}

.sub-cta {
  text-align: left;
}

.sub-cta:last-child {
  padding-top: 5px;
}

.sub-status-grid .MuiGrid-root .MuiGrid-item {
  margin-top: 0;
  padding-top: 0;
}

.task-week {
  background: #0c0c0c;
}

.task-header {
  background: #434131;
  padding: 10px 20px;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  line-height: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task-historical {
  margin-bottom: 15px;
}

.task-title-container > span {
  display: block;
}

.task-title-container .task-title {
  font-size: 1.3em;
  line-height: 1.3em;
}

.task-title-container .task-season {
  color: #d7d0b0;
  font-size: 0.9em;
}

.task-header .task-ends {
  font-size: 1.2em;
  color: #d55753;
}

.task-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 15px;
}

.task-map {
  height: 120px;
  position: relative;
  z-index: 1;
  background-position: center;
  background-size: cover;
  width: 100%;
  border: 4px solid #1e1d18;
}

.task-map-label {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  display: block;
  background: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  font-family: 'Bender Bold';
  text-align: center;
  color: #a9a9a9;
  padding: 3px 0;
}

.task-desc {
  display: flex;
  flex-grow: 1;
  color: #707070;
}

.task-ends .timer-label {
  padding: 0 2px;
}

.task-objectives {
  padding: 0 15px 15px;
  font-family: 'Bender Bold';
}

.task-objectives-title {
  color: #707070;
  padding-bottom: 5px;
}

.task-objectives-item {
  background: #333;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.task-objectives-item:last-child {
  margin-bottom: 0;
}

.task-objectives-label {
  color: #c4c1b3;
}

.task-rewards-list .league-prizes-place {
  background-color: #181816;
  padding: 15px 20px 15px 45px;
  margin-right: 15px;
  background-position: 18px center;
}

.get-started-banner-steps {
  font-family: 'Khand';
  font-weight: 700;
  color: #2f4800;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  flex-grow: 1;
  position: relative;
  z-index: 1;
  height: 20px;
}

.get-started-banner-steps-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: right;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
}

.get-started-banner-steps-bg {
  display: flex;
  align-items: center;
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 20px;
}

.get-started-banner-steps-bg-bar {
  width: 100%;
  height: 4px;
  background-color: #619500;
}

.get-started-banner-step {
  display: flex;
  align-items: center;
  line-height: 1em;
  text-decoration: none;
  padding: 0 15px;
  margin-left: 50px;
  background-color: #7ab903;
}

.get-started-banner-step:last-child {
  padding-right: 0;
}

.get-started-banner-step-number {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-family: 'Bender Bold';
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2f4800;
  color: #7ab903;
  font-size: 0.8em;
}

.get-started-banner-step-text {
  display: flex;
  align-items: center;
  padding-top: 3px;
  padding-left: 5px;
  color: #2f4800;
}

.top-vodders {
  padding-top: 15px;
  display: block;
}

.top-vodder {
  background-color: #181816;
  padding: 15px 20px 15px 40px;
  display: flex;
  align-items: center;
  background-position: 15px center !important;
  margin-bottom: 10px;
}

.top-vodder:last-child {
  margin-bottom: 0;
}

.top-vodder-count {
  padding-left: 10px;
  color: #707070;
}

.rbc-overlay {
  background-color: #000 !important;
  border: 1px solid #222 !important;
}

.rbc-overlay-header {
  font-family: 'Bender Bold';
  color: #7ab903;
  border-bottom: 1px solid #222 !important;
}

.rbc-show-more {
  background-color: #222 !important;
  padding: 5px !important;
  font-family: 'Bender Bold';
  color: #7ab903 !important;
}

.task-header .task-ends .task-starts {
  color: #619500;
}

.premium-badge {
  padding-right: 25px;
  background: url(../img/icon-premium-green.png) no-repeat center right;
  background-size: 15px auto;
}

.duos-invite-form {
  margin-top: -20px;
  background: #0c0c0c;
  padding: 15px;
}

.duos-invite-form .helper-text {
  display: block;
  padding: 20px 0;
}

.buttons-right {
  display: flex;
  align-items: center;
  justify-content: right;
}

.duos-invite-form .buttons-right {
  padding-top: 20px;
}

.pending-invite {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pending-invite .invite-user {
  display: flex;
  align-items: center;
}

.pending-invite .invite-username {
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.pending-invite .invite-avatar img {
  width: 40px;
  height: auto;
}

.pending-invite .invite-display-name {
  display: inline-block;
  color: #7ab903;
  font-family: 'Bender Bold';
  font-size: 1.15em;
}

.pending-invite .invite-status {
  font-family: 'Bender';
  display: inline-block;
  padding-left: 5px;
}

.nav-items-right {
  display: flex;
  align-items: center;
}

.invites {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.invites img {
  width: 30px;
  height: auto;
}

.invites-badge .MuiBadge-badge {
  background-color: #e41a1a;
  font-family: 'Khand';
  font-weight: 900;
  font-size: 0.8em;
}

.invites-dropdown {
  padding: 10px 20px;
}

.invite-title {
  display: block;
  font-family: 'Bender Bold';
  text-transform: uppercase;
  color: #7ab903;
}

.invite-desc {
  font-family: 'Bender';
  display: block;
  padding-bottom: 10px;
}

.invites-dropdown .pending-invite {
  display: block;
}

.invites-dropdown .pending-invite .invite-button {
  padding-top: 15px;
}

.invites-dropdown .pending-invite button:first-child {
  margin-right: 15px;
}

.inline-pending-invites {
  padding-top: 15px;
}

.inline-pending-invites button:first-child {
  margin-right: 15px;
}

.my-team {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: 'Bender';
}

.team-label {
  display: block;
  background: #222;
  color: #999;
  text-align: center;
  font-family: 'Bender Bold';
  text-transform: uppercase;
  margin-bottom: 15px;
}

.team-member {
  display: flex;
  align-items: center;
}

.team-avatar {
  padding-right: 15px;
}

.team-avatar img {
  width: 40px;
  height: auto;
}

.invite-error {
  padding-bottom: 20px;
}

.team-disband {
  text-decoration: underline;
  cursor: pointer;
  color: #707070;
}

.team-start-run {
  background-color: #1e1d18;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px 20px;
}

.league-week-banner-usernames {
  display: flex;
  align-items: center;
}

.league-week-banner-username:first-child {
  padding-right: 20px;
}

.review-run-cols {
  align-items: stretch;
}

.review-run-cols .league-week-banner {
  height: 100%;
}

.review-run-cols .league-week-banner-text .ends {
  max-width: none;
}

.question-controls {
  display: flex;
  align-items: center;
  justify-content: right;
}

.league-run-summary .items-value,
.stats-summary-accordion .items-value {
  text-transform: capitalize;
}

.league-run-summary .league-week-banner-text {
  padding: 5px 0 0;
}

.duo-partners {
  display: flex;
  flex-direction: column;
}

.duo-partner {
  display: flex;
  align-items: center;
}

.duo-partner-points {
  margin-left: 15px;
  display: flex;
  align-items: center;
  color: #625f48;
  font-family: 'Bender Bold';
  font-size: 1.1em;
  cursor: pointer;
}

.duo-partner-points img {
  width: 14px;
  height: auto;
  margin-left: 5px;
}

.duo-partner-points span {
  font-size: 0.8em;
  display: inline-block;
  padding-left: 5px;
}

.duo-partners a {
  display: flex;
  align-items: center;
}

.duo-partner:first-child {
  margin-bottom: 10px;
}

.stats-partner {
  display: flex;
  align-items: center;
}

.stats-partner span {
  display: inline-flex;
  align-items: center;
}

.stats-partner-user {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.stats-partner-user .stats-partner-avatar {
  width: 26px;
  height: auto;
  margin-right: 10px;
}

.stats-partner-dash {
  padding: 0 5px;
}

.stats-partner-points {
  display: flex;
  align-items: center;
}

.stats-partner-points span {
  font-size: 0.8em;
  display: inline-block;
  padding-left: 5px;
}

.kick-step-one {
  padding-top: 20px;
}

.kick-controls button:first-child {
  margin-right: 15px;
}

.vod-timestamp {
  display: flex;
  align-items: center;
}

.vod-timestamp .MuiFormControl-root {
  display: flex;
  flex-grow: 1;
  padding-right: 20px;
}

.vod-timestamp .MuiFormControl-root:last-child {
  padding-right: 0;
}

.cotw-banner .content-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cotw-streamer {
  display: flex;
  align-items: center;
}

.cotw-avatar img {
  width: 66px;
  height: auto;
  border: 3px solid #35342b;
}

.cotw-details {
  padding-left: 20px;
}

.middle-content .cotw-details a {
  text-decoration: none;
  color: #fff;
  font-size: 1.2em;
}

.cotw-details span {
  display: block;
  line-height: 1em;
}

.cotw-details .cotw-username span {
  display: inline-block;
}

.cotw-details .cotw-league {
  color: #e7c212;
  font-family: 'Bender Bold';
  padding: 8px 0 5px;
  text-transform: uppercase;
  font-size: 0.9em;
}

.cotw-details .cotw-points {
  font-family: 'Bender Bold';
  display: flex;
  align-items: center;
  font-size: 1.1em;
  color: #625f48;
  line-height: 1em;
}

.cotw-details .cotw-points span {
  padding-left: 5px;
  font-size: 0.7em;
}

.cotw-ctas a button.btn-primary {
  border: none;
}

.cotw-ctas {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.cotw-ctas a {
  text-decoration: none !important;
}

.cotw-ctas a button {
  padding: 5px 20px;
  font-size: 0.9em;
}

.cotw-ctas a button:hover {
  padding: 5px 20px;
  font-size: 0.9em;
}

.cotw-ctas a:last-child {
  margin-top: 10px;
}

.cotw-ctas a:last-child button.btn-primary {
  background-color: #65624a;
}

.desktop-only,
.mobile-only {
  display: none !important;
}

.howto-buttons {
  padding: 10px 0 20px;
}

.paragraph.first {
  padding-top: 20px;
}

.paragraph.grey {
  color: #a9a9a9;
}

.howto-getting-started {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.howto-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.howto-img {
  max-width: 180px;
  border: 4px solid #35342b;
}

.howto-img img {
  width: 100%;
  height: auto;
}

.howto-step .paragraph {
  padding-top: 10px;
}

.howto-modal,
.start-run-modal {
  height: 100%;
  overflow-y: scroll;
}

.bounty-amount {
  padding-top: 20px;
}

a .MuiAlert-message {
  text-decoration: underline !important;
}

.bounty-buttons {
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.bounty-list {
  background: #161614;
  margin-bottom: 40px;
}

button.btn-won,
button.btn-won:hover {
  background: none !important;
  color: #7ab903 !important;
  cursor: default !important;
}

button.btn-lost,
button.btn-lost:hover {
  background: none !important;
  color: #e13c3c !important;
  cursor: default !important;
}

button.btn-ended,
button.btn-ended:hover {
  background: none !important;
  color: #707070 !important;
  cursor: default !important;
}

.bounty {
  font-family: 'Bender Bold';
  border-bottom: 3px solid #0c0c0c;
}

.bounty:first-child {
  border-top: none;
}

.bounty-task {
  background: #2b2b27;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.bounty-status,
.bounty-name {
  text-transform: uppercase;
  font-size: 1.2em;
}

.bounty-status.is-active {
  color: #7ab903;
}

.bounty-status.is-pending {
  color: #b96703;
}

.bounty-details {
  padding: 10px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.bounty-created {
  color: #707070;
}

.bounty-prize span {
  display: block;
  text-align: right;
}

.bounty-prize {
  display: block;
  text-align: right;
}

.bounty-prize-amount {
  font-size: 1.6em;
  line-height: 1em;
}

.bounty-prize-label {
  text-transform: uppercase;
  color: #707070;
}

.bounty-username {
  display: flex;
  align-items: center;
}

.bounty-username-label {
  display: inline-block;
  margin-right: 10px;
  color: #a9a9a9;
}

.bounty-username a {
  display: flex;
  align-items: center;
}

.bounty-username img {
  width: 24px;
  margin-right: 10px;
}

.bounty-username a {
  text-decoration: none;
  color: #a9a9a9 !important;
}

.bounty-entries {
  padding: 10px 20px 20px;
}

.bounty-entries-count {
  display: inline-block;
  padding-left: 10px;
  color: #837654;
  line-height: 1em;
}

.bounty-entries-inner {
  background: #0e0e0d;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bounty-buttons {
  padding-bottom: 20px;
}

.bounty-modal-task {
  font-family: 'Bender Bold';
  background: #1c1c1a;
  padding: 10px 20px;
  display: block;
  margin-bottom: 20px;
}

.entries-clickable {
  cursor: pointer;
  padding-right: 20px;
  background: url(../img/icon-down-gold.png) no-repeat center right;
  background-size: 14px auto;
}

.entries-clickable.entries-expanded {
  background: url(../img/icon-up-gold.png) no-repeat center right;
  background-size: 14px auto;
}

.entries-list {
  background: #000;
  display: none;
}

.entry-link {
  display: flex;
  align-items: center;
}

.entry-vodder {
  margin-left: 15px;
  cursor: pointer;
}

.entry-vodder img {
  width: 16px;
  height: auto;
}

.entries-list-expanded {
  display: block;
}

.bounty-entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 15px;
  z-index: 1;
}

.entry-user img {
  width: 30px;
  height: auto;
  margin-right: 15px;
}

.entry-user {
  display: flex;
  align-items: center;
}

.entry-position {
  display: inline-block;
  color: #837654;
  padding-right: 15px;
}

.entry-time {
  color: #707070;
}

.bounty-entry {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #161614;
}

.bounty-entry.entry-winner {
  background-color: rgba(122, 185, 3, 0.2);
}

.bounty-entry:last-child {
  border-bottom: none;
}

.is-banned {
  font-family: 'Bender Bold';
  background: #a02626;
  display: inline-block;
  line-height: 1em;
  padding: 3px 15px;
  border-radius: 5px;
  color: #000;
  text-transform: uppercase;
}

.bounty-overlay-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
}

.bounty-overlay {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  margin-top: -300px;
  height: 200px;
  transform: scale(0.1);
  transition: all 1000ms linear 0s, opacity 1000ms linear 500ms;
}

.bounty-overlay .bounty-left {
  background: url(../img/bounty-overlay-left-bg.jpg) repeat-x center center;
  width: 178px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 8px solid #8ad201;
}

.bounty-overlay .bounty-left img {
  margin-top: -10px;
  width: 90%;
  height: auto;
  opacity: 0;
  transition: opacity 500ms linear 1000ms;
}

.animate-overlay-bounty-enter-done {
  opacity: 0.9;
  margin-top: 50px;
  transform: scale(0.9);
}

.animate-overlay-bounty-exit-done {
  opacity: 0;
  margin-top: 100px;
  transform: scale(0.9);
  transition: all 1000ms linear 1200s, opacity 1000ms linear 700ms;
}

.animate-overlay-bounty-enter-done .bounty-left img {
  opacity: 1;
}

.animate-overlay-bounty-exit-done .bounty-left img {
  opacity: 0;
}

.bounty-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  overflow: hidden;
}

.bounty-right-label {
  margin-left: -2000px;
  transition: margin 500ms linear 1200ms;
}

.animate-overlay-bounty-enter-done .bounty-right-label {
  margin-left: 0;
}

.animate-overlay-bounty-exit-done .bounty-right-label {
  margin-left: -2000px;
  transition: margin 500ms linear 0ms;
}

.bounty-right-label > span {
  display: inline-block;
  background: #000;
  color: #e13c3c;
  padding: 20px 100px 20px 30px;
  font-size: 1.8em;
  clip-path: polygon(0 0, calc(100% - 40px) 0%, 100% 100%, 0% 100%);
}

.bounty-right-details {
  background: #8ad201 url(../img/bounty-overlay-desc-bg.jpg) repeat-x center
    bottom;
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-left: 15px;
  clip-path: polygon(0 0, 100% 0, calc(100% - 100px) 100%, 0% 100%);
  margin-left: -2000px;
  transition: margin 500ms linear 1200ms;
}

.animate-overlay-bounty-enter-done .bounty-right-details {
  margin-left: 0;
}

.animate-overlay-bounty-exit-done .bounty-right-details {
  margin-left: -2000px;
  transition: margin 500ms linear 0ms;
}

.bounty-right-posted {
  display: flex;
  align-items: center;
  margin-left: -2000px;
  transition: margin 500ms linear 1200ms;
}

.animate-overlay-bounty-enter-done .bounty-right-posted {
  margin-left: 0;
}

.animate-overlay-bounty-exit-done .bounty-right-posted {
  margin-left: -2000px;
  transition: margin 500ms linear 0ms;
}

.bounty-right-posted-inner {
  background: #000;
  color: #b9b9b9;
  padding: 10px 20px;
  font-size: 1em;
  display: inline-flex;
  align-items: center;
  clip-path: polygon(0 0, 100% 0, calc(100% - 50px) 100%, 0% 100%);
  padding-right: 100px;
  min-width: 70%;
}

.bounty-right-posted img {
  width: 30px;
  height: auto;
  margin: 0 10px;
}

.bounty-right-prize {
  background: #000;
  color: #8ad201;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 2.2em;
  line-height: 1em;
  margin-right: 20px;
}

.bounty-right-task {
  font-size: 1.75em;
  min-width: 700px;
  padding-right: 100px;
}

.blink_me {
  animation: blinker 2s linear 3;
}

.select-winner {
  font-family: 'Bender Bold';
  display: flex;
  align-items: center;
}

.select-winner img {
  width: 30px;
  height: auto;
}

.select-winner .winner-position {
  display: inline-block;
  padding-right: 15px;
}

.select-winner .winner-username {
  display: inline-block;
  padding-left: 15px;
}

.events-box {
  background: rgba(21, 21, 21, 0.9);
}

.events-box-header {
  background: #434131;
  padding: 10px 20px;
  text-transform: uppercase;
  font-family: 'Bender Bold';
  line-height: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.events-row {
  text-decoration: none !important;
}

.events-row-info {
  margin-top: 15px;
  padding: 15px;
  background: #1c1c1a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.events-row-desc {
  display: flex;
  align-items: center;
}

.events-row-desc span,
.events-row-info span {
  display: block;
  color: #fff;
}

.events-row-title {
  padding-top: 10px;
  font-family: 'Bender Bold';
  font-size: 1em;
  line-height: 1em;
}

.events-row-organizer {
  font-family: 'Bender Bold';
  font-size: 0.8em;
  line-height: 1em;
  text-transform: uppercase;
  color: #999 !important;
  padding: 3px 0;
}

.event-organizer {
  padding: 10px 15px 15px;
  background-color: #161614;
}

.event-organizer-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Bender Bold';
  color: #fff;
  font-size: 1em;
  line-height: 1em;
  padding-bottom: 5px;
}

.event-organizer-title .player-socials {
  padding-left: 10px;
}

.event-organizer-title .player-socials a:last-child {
  margin-right: 0;
}

.event-organizer-desc {
  display: block;
  color: #999;
  font-size: 0.95em;
  line-height: 1.4em;
}

.events-row-subtitle,
.events-row-info-value {
  color: #999 !important;
}

.events-box-content {
  padding: 0 15px;
}

.events-row-thumb {
  width: 80px;
  height: 80px;
  min-width: 80px;
  background-position: center center;
  background-size: 350% auto;
  border: 4px solid #1e1d18;
}

.events-row-desc-content {
  padding-left: 15px;
  padding-right: 15px;
}

.events-row-thumb img {
  border: 4px solid #1e1d18;
  width: 80px;
  height: auto;
}

.events-row .ended,
.sidebar-events-item .past {
  color: #d55753 !important;
}

.events-row .ongoing,
.sidebar-events-item .ongoing {
  color: #7ab903 !important;
}

.events-row .upcoming,
.sidebar-events-item .upcoming {
  color: #7ab903 !important;
}

.flag-icon {
  width: 26px;
  height: auto;
  margin-right: 10px;
}

.online-icon {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

.sidebar-events-item .flag-icon {
  width: 20px !important;
  margin-right: 5px !important;
}

.sidebar-events-item .online-icon {
  width: 16px !important;
  margin-right: 5px !important;
}

.events-row-location {
  display: flex !important;
  align-items: center;
  padding-top: 3px;
}

.events-row-location span {
  display: inline-block;
  color: #6d6c63;
  font-family: 'Bender Bold';
  font-size: 0.8em;
  line-height: 1em;
  padding-top: 1px;
}

.events-row-prize {
  margin-right: 15px;
  background: #080808;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 17px 15px 16px;
}

.events-row-prize .events-row-info-label {
  text-transform: uppercase;
  font-family: 'Bender Bold';
  line-height: 1em;
  color: #474842;
}

.events-row-prize .events-row-info-value {
  text-transform: uppercase;
  font-family: 'Bender Bold';
  color: #8a8a88;
  font-size: 1.3em;
}

.event-header {
  padding-top: 25%;
  background-size: 100% auto !important;
  position: relative;
  z-index: 1;
}

.event-status {
  position: absolute;
  z-index: 2;
  top: 15px;
  right: 15px;
  background: #b94744;
  font-family: 'Bender Bold';
  text-transform: uppercase;
  color: #000;
  line-height: 1em;
  border-radius: 5px;
  font-size: 0.7em;
  padding: 3px 8px 2px;
}

.event-status-green {
  background: #7ab903;
}

.events-row-game {
  width: 80px;
  height: 80px;
  background: #080808;
  margin-right: 15px;
}

.game-tarkovarena {
  background: url(../img/game-arena.jpg) no-repeat center center;
  background-size: 100% auto;
}

.game-tarkov {
  background: url(../img/game-tarkov.jpg) no-repeat center center;
  background-size: 100% auto;
}

.event-page-details {
  background: #161614;
  padding: 15px 20px;
  color: #fff;
  overflow: hidden;
}

.event-detail {
  float: left;
  width: calc(50% - 10px);
}

.event-detail:nth-child(odd) {
  margin-right: 20px;
  margin-bottom: 10px;
}

.event-detail span {
  display: block;
}

.event-detail-label {
  font-family: 'Bender Bold';
}

.event-detail-value {
  font-family: 'Bender';
  font-size: 0.9em;
  color: #aaa;
}

.events-standings {
  margin-top: 15px;
  overflow-x: auto;
}

.events-standings-map,
.events-photos,
.events-highlights,
.events-comments,
.events-head2head,
.events-history,
.events-bracket {
  margin-top: 15px;
}

.events-standings .events-box-header,
.events-mvp .events-box-header {
  min-width: 500px;
}

.event-standings-header,
.event-results-header,
.match-stats-header {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background: rgba(21, 21, 21, 0.9);
  font-family: 'Bender Bold';
  line-height: 1em;
  font-size: 0.9em;
  color: #585858;
  text-transform: uppercase;
  border-bottom: 1px solid #222;
  min-width: 500px;
}

.event-standings-row,
.event-results-row,
.match-stats-row {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 2px solid #0c0c0c;
  min-width: 500px;
}

.event-challenge-standings-row-inner {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 2px solid #0c0c0c;
  min-width: 500px;
}

.match-stats-row {
  padding: 5px 20px;
}

.event-results-row {
  background: #1c1c1a;
}

.event-standings-row span,
.event-results-row span,
.match-stats-row span,
.event-challenge-standings-row-inner span {
  display: inline-block;
}

.event-results-row span {
  color: #fff;
}

.event-results-row a {
  display: flex;
  width: 100%;
  align-items: center;
  text-decoration: none;
}

.event-results-row a > span:nth-child(1) {
  width: 30%;
  text-align: right;
}

.event-results-row a > span:nth-child(2) {
  width: 15%;
  text-align: center;
  font-family: 'Bender Bold';
  color: #585858;
}

.event-results-row a > span:nth-child(3) {
  width: 30%;
}

.event-results-row a > span:nth-child(4) {
  width: 25%;
  text-align: right;
  color: #999;
  font-family: 'Bender Bold';
}

.match-stats-header span,
.match-stats-row span {
  width: 9%;
  max-width: 70px;
  text-align: center;
}

.match-stats-row > span {
  color: #999;
  font-family: 'Bender Bold';
  font-size: 0.9em;
}

.match-stats-label.winner {
  color: #7ab903;
}

.match-stats-label.loser {
  color: #dc563f;
}

.match-stats-header span:first-child,
.match-stats-row span:first-child {
  flex-grow: 1;
  max-width: none;
  text-align: left;
}

.match-stats-header {
  margin-top: 20px;
}

.match-stats-row span:first-child a {
  color: #7ab903 !important;
}

.event-team-loser span {
  color: #a9a9a9 !important;
}

.event-results-winner {
  color: #619500 !important;
  display: inline-block;
  padding: 0 2px;
}

.event-results-loser {
  color: #b94744 !important;
  display: inline-block;
  padding: 0 2px;
}

.event-standings-row > *:nth-child(1),
.event-standings-header > *:nth-child(1),
.event-challenge-standings-row-inner > *:nth-child(1) {
  width: 15%;
  font-family: 'Bender Bold';
}

.event-challenge-standings-header > *:nth-child(1) {
  width: 12%;
}

.event-challenge-standings-row-inner > *:nth-child(1) {
  width: 12%;
  color: #65624a;
}

.event-standings-row > *:nth-child(1),
.event-challenge-standings-row-inner > *:nth-child(1) {
  display: flex;
  align-items: center;
}

.event-standings-row > *:nth-child(1) img,
.event-challenge-standings-row-inner > *:nth-child(2) img {
  width: 22px;
  height: auto;
  margin-right: 10px;
}

.event-standings-row > *:nth-child(2),
.event-standings-header > *:nth-child(2),
.event-challenge-standings-row-inner > *:nth-child(2) {
  width: 65%;
}

.event-challenge-standings-header > *:nth-child(2),
.event-challenge-standings-row-inner > *:nth-child(2) {
  width: 60%;
}

.event-standings-row > *:nth-child(3),
.event-standings-header > *:nth-child(3),
.event-challenge-standings-row-inner > *:nth-child(3) {
  width: 10%;
  text-align: center;
}

.event-challenge-standings-header > *:nth-child(3),
.event-challenge-standings-row-inner > *:nth-child(3) {
  width: 18%;
}

.event-standings-row > *:nth-child(4),
.event-standings-header > *:nth-child(4),
.event-challenge-standings-row-inner > *:nth-child(4) {
  width: 10%;
  text-align: right;
}

.event-standings-row > *:nth-child(3),
.event-standings-row > *:nth-child(4),
.event-challenge-standings-row-inner > *:nth-child(3),
.event-challenge-standings-row-inner > *:nth-child(4) {
  color: #999;
}

.event-challenge-divider {
  text-align: center;
  padding: 5px 15px;
  border-bottom: 2px solid #000;
  text-transform: uppercase;
  color: #837654;
  font-family: 'Bender Bold';
  background-color: #1c1c1a;
  font-size: 0.8em;
  line-height: 1em;
}

.event-challenge-score {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}

.event-challenge-score img {
  cursor: pointer;
  width: 16px;
  height: auto;
  margin-left: 10px;
}

.event-challenge-scores {
  display: flex;
  flex-direction: column;
  background-color: #0a0a08;
}

.event-challenge-scores-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #100f0f;
  border-bottom: 1px solid #000;
  font-size: 0.95em;
  line-height: 1em;
}

.event-challenge-scores-item:last-child {
  border: none;
  margin-bottom: 40px;
}

.event-challenge-score-label {
  color: #999;
}

.event-challenge-score-value {
  color: #65624a;
}

.event-standings-label-team {
  display: flex;
  align-items: center;
}

.event-results-label-team-one {
  display: flex !important;
  align-items: center;
  justify-content: right;
}

.event-results-label-team-two {
  display: flex !important;
  align-items: center;
  justify-content: left;
}

.event-standings-label-team img {
  width: 30px;
  height: auto;
  margin-right: 10px;
}

.event-results-label-team-one img {
  width: 24px;
  height: auto;
  margin-right: 10px;
}

.event-results-label-team-two img {
  width: 24px;
  height: auto;
  margin-left: 10px;
}

.events-mvp {
  margin-top: 20px;
  overflow-x: auto;
}

.events-mvp .events-box-header {
  background: #7ab903;
  color: #000;
  min-width: 600px;
}

.events-mvp-image {
  width: 200px;
  height: 200px;
  background-size: cover !important;
  border: 4px solid #1e1d18;
  margin-right: 20px;
  min-width: 150px;
}

.events-mvp-content {
  padding: 20px;
  display: flex;
  align-items: center;
  min-width: 600px;
}

.events-mvp-user a,
.match-stats-user a {
  display: flex;
  align-items: center;
}

.events-mvp-user img,
.match-stats-user img {
  width: 30px;
  height: auto;
  margin-right: 10px;
}

.match-stats-user img {
  width: 24px;
}

.events-mvp-candles {
  padding: 25px 20px 20px 40px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.events-mvp-candle {
  width: 30px;
  height: 120px;
  margin-right: 20px;
  position: relative;
  z-index: 2;
}

.events-mvp-candle-inner {
  width: 100%;
  height: 100%;
  background: #619500;
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 0;
}

.events-mvp-avg {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 18%;
  width: 100%;
  border-bottom: 2px solid #999;
  color: #999;
  font-family: 'Bender Bold';
}

.events-mvp-candle-label {
  position: absolute;
  z-index: 8;
  display: inline-block;
  width: 30px;
  bottom: -20px;
  left: 0;
  text-align: center;
  font-family: 'Bender Bold';
  color: #999;
  font-size: 0.8em;
}

.events-mvp-candle-score {
  position: absolute;
  z-index: 10;
  display: inline-block;
  width: 30px;
  top: -20px;
  left: 0;
  text-align: center;
  font-family: 'Bender Bold';
  color: #fff;
  font-size: 0.8em;
}

.events-mvp-candle-rating {
  margin-left: 20px;
}

.events-mvp-candle-rating .events-mvp-candle-inner {
  background-color: #8ad201;
}

.events-box .no-results {
  padding: 15px;
  display: inline-block;
  color: #999;
}

.match-stats-no-results {
  padding: 15px 20px;
  display: block;
  color: #999;
  font-family: 'Bender';
}

.events-bracket {
  overflow-x: auto;
}

.events-bracket .events-box-header {
  min-width: 630px;
}

.bracket-container {
  min-width: 630px;
  padding: 20px;
}
.bracket {
  display: inline-block;
  white-space: nowrap;
  font-size: 0;
  font-family: 'Bender Bold';
  line-height: 1em;
}
.bracket .round {
  display: inline-block;
  vertical-align: middle;
}
.bracket .round .winners > div {
  display: inline-block;
  vertical-align: middle;
}
.bracket .round .winners > div.matchups .matchup:last-child {
  margin-bottom: 0 !important;
}
.bracket .round .winners > div.matchups .matchup .participants {
  border-radius: 0.25rem;
  overflow: hidden;
  text-decoration: none;
  display: inline-block;
}
.bracket .round .winners > div.matchups .matchup .participants .participant {
  box-sizing: border-box;
  color: #999;
  border-left: 5px solid #dc563f;
  background: #0e0e0d;
  width: 16rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
}

.bracket .participant > span:first-child {
  display: flex;
  align-items: center;
}

.bracket .participant > span:first-child img {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

.bracket
  .round
  .winners
  > div.matchups
  .matchup
  .participants
  .participant.winner {
  color: #fff;
  border-color: #7ab903;
}
.bracket
  .round
  .winners
  > div.matchups
  .matchup
  .participants
  .participant.loser {
  color: #999;
  border-color: #dc563f;
}
.bracket
  .round
  .winners
  > div.matchups
  .matchup
  .participants
  .participant:not(:last-child) {
  border-bottom: 2px solid #000;
}
.bracket
  .round
  .winners
  > div.matchups
  .matchup
  .participants
  .participant
  > span {
  margin: 0 1rem;
  line-height: 1rem;
  font-size: 1rem;
}
.bracket .round .winners > div.connector.filled .line,
.bracket .round .winners > div.connector.filled.bottom .merger:after,
.bracket .round .winners > div.connector.filled.top .merger:before {
  border-color: #7ab903;
}
.bracket .round .winners > div.connector .line,
.bracket .round .winners > div.connector .merger {
  box-sizing: border-box;
  width: 2rem;
  display: inline-block;
  vertical-align: top;
}
.bracket .round .winners > div.connector .line {
  border-bottom: 2px solid #000;
  height: 4rem;
  margin-top: -2px;
}
.bracket .round .winners > div.connector .merger {
  position: relative;
  height: 7.1rem;
  margin-top: -2px;
}
.bracket .round .winners > div.connector .merger:before,
.bracket .round .winners > div.connector .merger:after {
  content: '';
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 50%;
  border: 0 solid;
  border-color: #000;
}
.bracket .round .winners > div.connector .merger:before {
  border-right-width: 2px;
  border-top-width: 2px;
}
.bracket .round .winners > div.connector .merger:after {
  border-right-width: 2px;
  border-bottom-width: 2px;
}
.bracket .round.quarterfinals .winners:not(:last-child) {
  margin-bottom: 2rem;
}
.bracket .round.quarterfinals .winners .matchups .matchup:not(:last-child) {
  margin-bottom: 2rem;
}
.bracket .round.semifinals .winners .matchups .matchup:not(:last-child) {
  margin-bottom: 10rem;
}
.bracket .round.semifinals .winners .connector .merger {
  height: 16rem;
}
.bracket .round.semifinals .winners .connector .line {
  height: 8rem;
}
.bracket .round.finals .winners .connector .merger {
  height: 3rem;
}
.bracket .round.finals .winners .connector .line {
  height: 1.5rem;
}
.showmatch {
  padding-top: 70px;
}
.showmatch .round {
  margin-top: -155px;
}
.showmatch .round:last-child {
  margin-top: -2px;
}
.showmatch .showmatch-matchup {
  margin-top: -100px;
}
.bracket
  .round
  .winners
  > div.matchups
  .matchup
  .participants
  .participant.winner
  span.winner {
  color: #7ab903;
}
.bracket
  .round
  .winners
  > div.matchups
  .matchup
  .participants
  .participant
  span.loser {
  color: #dc563f;
}

.events-maps {
  overflow: hidden;
  padding: 15px 15px 0;
}

.events-map {
  float: left;
  width: calc(50% - 8px);
}

.events-map,
.round-map-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.events-map span,
.round-map-img span {
  display: inline-block;
  font-family: 'Bender Bold';
  text-shadow: 0 0 2px #000, 0 0 3px #000, 0 0 5px #000, 0 0 2px #000,
    0 0 3px #000, 0 0 5px #000;
  text-transform: uppercase;
  font-size: 1.2em;
  color: #fff;
}

.events-map.airpit,
.round-map-img.airpit {
  background: url(../img/arena-map-airpit.jpg) no-repeat center center;
}

.events-map.bay5,
.round-map-img.bay5 {
  background: url(../img/arena-map-bay5.jpg) no-repeat center center;
}

.events-map.equator,
.round-map-img.equator {
  background: url(../img/arena-map-equator.jpg) no-repeat center center;
}

.events-map.sawmill,
.round-map-img.sawmill {
  background: url(../img/arena-map-sawmill.jpg) no-repeat center center;
}

.round-map {
  display: flex;
  align-items: center;
  padding: 30px 15px 15px;
  border-bottom: 2px solid #000;
}

.round-map:last-child {
  border-bottom: none;
}

.events-map,
.round-map-img {
  background-size: cover !important;
  height: 100px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.events-map:nth-child(even) {
  margin-right: 0;
}

.round-map-img {
  min-width: 50px;
  width: 50px;
  height: 50px;
  margin-right: 0;
}

.round-map-details {
  display: flex;
  align-items: center;
}

.round-map-round {
  padding-left: 10px;
  position: relative;
  z-index: 1;
}

.round-map-round:first-child {
  padding-left: 15px;
  padding-right: 5px;
}

.round-map-team {
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.round-map-number {
  display: inline-block;
  width: 20px;
  text-align: center;
  position: absolute;
  z-index: 2;
  top: -20px;
  right: 0;
  font-family: 'Bender Bold';
  color: #585858;
  font-size: 0.8em;
}

.round-map-final {
  font-family: 'Bender Bold';
  color: #585858;
  font-size: 0.8em;
}

.round-map-final.winner {
  color: #7ab903;
}

.round-map-final.loser {
  color: #dc563f;
}

.round-map-team img {
  width: 60%;
  height: auto;
}

.round-map-team .round-map-team-img {
  width: 100%;
  height: auto;
}

.round-map-team:last-child {
  margin-bottom: 0;
}

.events-map:last-child {
  margin-right: 0;
}

.events-embed-container {
  padding: 15px;
}

.events-embed {
  width: 100%;
  padding-top: 56%;
  position: relative;
  z-index: 1;
}

.events-embed iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}

.events-stats-filters {
  background: #0e0e0d;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: left;
  min-width: 500px;
  width: 100%;
}

.events-stats-filters .events-stats-filter {
  margin-left: 10px !important;
  font-family: 'Bender Bold';
  text-transform: uppercase;
  border: 2px solid #585858;
  color: #585858;
  font-size: 0.75em;
}

.events-stats-filters .events-stats-filter.MuiChip-filled {
  background: #434131;
  border: 2px solid #434131;
  color: #fff;
}

.match-header {
  background: #000;
  padding: 20px 15px;
  font-family: 'Bender';
  line-height: 1em;
}

.match-header-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Bender Bold';
  font-size: 0.9em;
  color: #999;
}

.match-header-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.match-header-info span {
  display: inline-block;
}

.match-header-info span.match-header-results {
  text-align: center;
}

.match-header-info span.match-header-results > span {
  display: block;
}

.match-header-score {
  font-family: 'Bender Bold';
  font-size: 2.5em;
  line-height: 1em;
  padding: 10px 0;
  color: #585858;
  display: flex;
  align-items: center;
}

.match-header-score .divider {
  color: #585858;
  font-family: 'Bender';
  display: inline-block;
  padding: 0 5px;
}

.match-header-score .winner {
  color: #7ab903;
}

.match-header-score .loser {
  color: #dc563f;
}

.match-header-round {
  font-size: 0.9em;
  font-family: 'Bender Bold';
  color: #585858;
}

.match-header-datetime {
  font-size: 0.8em;
  color: #999;
  padding: 0 20px;
}

.match-header-info .match-header-team {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  text-decoration: none;
}

.match-header-team-logo {
  width: 40px;
  height: auto;
  margin: 0 auto;
}

.match-header-team-name {
  display: block;
  font-size: 0.8em;
  text-align: center;
}

.match-past-matches-outer {
  padding: 15px;
}

.match-past-matches {
  background-color: #0e0e0d;
}

.match-past-team:last-child {
  border-right: none;
}

.match-past-team-header {
  padding: 10px 15px;
}

.match-past-team-header a {
  display: flex;
  align-items: center;
}

.match-past-team-header img {
  width: 40px;
  height: auto;
  margin: 0 10px 0 0;
}

.match-past-match {
  background-color: #1c1c1a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Bender Bold';
  padding: 10px 15px;
  font-size: 0.9em;
  line-height: 1em;
  border-bottom: 2px solid #000;
  text-decoration: none !important;
  color: #fff !important;
}

.match-past-match-info > span {
  display: block;
}

.match-past-match:last-child {
  border-bottom: none;
}

.match-past-date {
  color: #585858;
}

.match-past-match.winner {
  border-right: 4px solid #7ab903;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.match-past-match.loser {
  border-right: 4px solid #dc563f;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.match-past-match.winner .match-past-score {
  color: #7ab903;
}

.match-past-match.loser .match-past-score {
  color: #dc563f;
}

.match-head-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
}

.match-head-score {
  padding-right: 10px;
}

.match-head-score:nth-child(4) {
  padding-right: 0;
}

.match-head-score span {
  display: block;
  text-align: center;
  font-family: 'Bender Bold';
}

.match-head-score-value {
  font-size: 2em;
  line-height: 1em;
  color: #999;
}

.match-head-score-label {
  font-size: 0.8em;
  line-height: 1em;
  text-transform: uppercase;
  color: #585858;
}

.match-head-team {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff !important;
  text-decoration: none;
}

.match-head-team span {
  text-align: center;
  font-size: 0.8em;
  line-height: 1em;
}

.match-head-match {
  display: block;
  background-color: #1c1c1a;
  padding: 10px 15px;
  border-bottom: 2px solid #000;
  text-decoration: none !important;
}

.match-head-match:last-child {
  border-bottom: none;
}

.past-events {
  margin-top: 20px;
}

.match-head-thumb-img {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  padding-top: 20%;
  border: 4px solid #1e1d18;
  margin-bottom: 10px;
}

.match-head-team-logo {
  width: 30px;
  height: auto;
}

.match-head-info {
  display: inline-block;
  padding-bottom: 10px;
}

.match-head-info span {
  display: block;
  font-size: 0.9em;
  line-height: 1.1em;
  color: #999;
}

.match-head-title {
  font-family: 'Bender Bold';
}

.match-head-subhead {
  color: #585858;
}

.match-head-date {
  text-align: right;
  font-size: 0.9em;
  line-height: 1em;
  color: #999;
}

.match-head-match-results {
  display: flex;
  align-items: center;
}

.match-head-match-results img:first-child {
  margin-right: 10px;
}

.match-head-match-score {
  display: inline-block;
  text-align: center;
  padding: 3px 10px;
  font-size: 0.9em;
  line-height: 1em;
  color: #000;
  font-family: 'Bender Bold';
  background-color: #999;
  border-radius: 5px;
  margin-right: 10px;
}

.match-head-match-score.winner {
  background-color: #7ab903;
}

.match-head-match-score.loser {
  background-color: #dc563f;
}

.content-panel .events-box:first-child {
  margin-top: 0;
}

.match-two-up > * {
  width: 100%;
  display: block;
  padding: 10px 15px;
  font-size: 0.9em;
  margin-bottom: 15px;
}

.match-two-up > *:last-child {
  margin-bottom: 0;
}

.match-vod-link {
  background: url(../img/icon-new-tab.png) no-repeat 15px center;
  padding-left: 40px;
  background-size: 18px auto;
  background-color: #18141c;
  text-decoration: none;
  color: #999 !important;
  font-family: 'Bender Bold';
  text-transform: uppercase;
}

.match-completed {
  background-color: #1a1110;
  color: #dc563f !important;
  font-family: 'Bender Bold';
  text-transform: uppercase;
}

.tournaments-panel .events-box {
  margin-bottom: 20px;
}

.tournaments-panel .events-box:last-child {
  margin-bottom: 0;
}

.overlay-streamer-challenge .bounty-right-posted-inner {
  color: #8ad201;
  font-family: 'Bender Bold';
  letter-spacing: 1px;
  font-size: 0.9em;
  line-height: 1em;
  clip-path: polygon(0 0, 100% 0, calc(100% - 30px) 100%, 0% 100%);
}

.overlay-amped-major .bounty-right-posted-inner {
  color: #787878;
}

.overlay-streamer-challenge .bounty-right-label > span {
  color: #b1b8c3;
  font-size: 1em;
  line-height: 1em;
  padding: 8px 100px 8px 30px;
  clip-path: polygon(0 0, calc(100% - 25px) 0%, 100% 100%, 0% 100%);
  letter-spacing: 1px;
}

.overlay-amped-major .bounty-right-label > span {
  padding: 8px 100px 8px 20px;
  color: #fff;
  font-size: 1.2em;
  min-width: 300px;
}

.overlay-streamer-challenge .bounty-right-details {
  background: #9ca2ae url(../img/bounty-overlay-desc-bg-grey.jpg) repeat-x
    center bottom;
  clip-path: polygon(0 0, 100% 0, calc(100% - 50px) 100%, 0% 100%);
}

.overlay-amped-major .bounty-right-details {
  background-image: url(../img/bounty-overlay-desc-bg-amped.jpg);
}

.overlay-streamer-challenge .bounty-overlay {
  height: auto;
}

.overlay-streamer-challenge .bounty-overlay .bounty-left {
  border-right: 8px solid #9ca2ae;
  width: 120px;
}

.overlay-amped-major .bounty-overlay .bounty-left {
  border-right: 8px solid #a42b2d;
}

.overlay-streamer-challenge .bounty-right-prize {
  color: #fff;
  padding: 8px 15px;
  position: relative;
  z-index: 1;
  padding-right: 80px;
  font-size: 1.5em;
  line-height: 1em;
}

.overlay-amped-major .bounty-right-prize {
  color: #fff;
  padding: 6px 15px;
  position: relative;
  z-index: 1;
  padding-right: 60px;
  font-size: 1.25em;
  line-height: 1em;
  border: 2px solid #bb4143;
  background-color: #280f10;
  margin-right: 10px;
}

.overlay-streamer-challenge .bounty-right-posted-inner {
  padding: 8px 30px;
}

.overlay-amped-major .bounty-right-posted-inner {
  padding: 8px 30px 8px 20px;
}

.overlay-streamer-challenge .bounty-right-posted-label {
  padding-right: 25px;
}

.overlay-amped-major .bounty-right-posted-label {
  display: flex;
  align-items: center;
}

.overlay-amped-major .bounty-right-posted-label img {
  width: 187px;
  height: auto;
  margin: 0 15px 0 0;
}

.overlay-streamer-challenge .bounty-right-prize-label {
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5em;
  line-height: 1.2em;
  color: #9ca2ae;
}

.overlay-amped-major .bounty-right-prize-label {
  right: 12px;
}

.overlay-amped-major .bounty-right-prize-label {
  color: #d4b7b8;
}

.overlay-streamer-challenge .bounty-right-details {
  padding-right: 80px;
}

.overlay-streamer-challenge .text-red {
  color: #dc563f;
}

.overlay-amped-major .text-red {
  color: #dc563f;
}

.overlay-streamer-challenge .text-green {
  color: #7ab903;
}

.overlay-amped-major .text-green {
  color: #8cd53d;
}

.overlay-streamer-challenge .streamer-rank {
  color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.overlay-streamer-challenge .streamer-rank-place {
  font-size: 1.5em;
  line-height: 1em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  min-width: 70px;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-streamer-challenge .streamer-rank-place-label {
  color: #adb5c1;
  font-size: 1.2em;
}

.overlay-streamer-challenge.bounty-overlay-wrapper {
  display: block;
  width: auto;
  top: 15px;
  left: 15px;
}

.overlay-streamer-challenge .animate-overlay-bounty-enter-done {
  margin-top: 0;
  transform: scale(1);
  opacity: 0.95;
}

.overlay-streamer-challenge .bounty-left,
.overlay-streamer-challenge .bounty-right {
  height: 120px;
}

.overlay-streamer-challenge .text-gold {
  color: #f5e977;
}

.overlay-streamer-challenge .text-silver {
  color: #cfcfcf;
}

.overlay-streamer-challenge .text-bronze {
  color: #ecb57f;
}

.overlay-amped-major .amped-icons {
  padding-right: 15px;
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.amped-icon {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.amped-icon:last-child {
  padding-right: 0;
}

.amped-icon span {
  display: inline-block;
  line-height: 1em;
  padding-top: 1px;
  padding-left: 10px;
  font-family: 'Bender';
  font-weight: normal;
  color: #d4b7b8;
  padding: 6px 0 6px 8px;
}

.amped-icon.icon-kills img {
  width: 15px;
  height: auto;
}

.amped-icon.icon-tags img {
  width: 17px;
  height: auto;
}

.amped-icon.icon-scavs img {
  width: 24px;
  height: auto;
}

.amped-icon.icon-bosses img {
  width: 18px;
  height: auto;
}

.amped-icon.icon-items img {
  width: 16px;
  height: auto;
}

.team-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #161614;
  padding: 15px;
}

.team-header .team-details {
  padding-top: 10px;
}

.team-header .team-details > span {
  display: block;
}

.team-header .team-avatar {
  width: 100px;
  height: auto;
}

.team-header .team-rank-details > span {
  display: block;
  text-align: right;
}

.team-name {
  font-size: 1.2em;
  line-height: 1em;
  font-family: 'Bender Bold';
  padding-bottom: 5px;
}

.team-location {
  color: #999;
}

.team-rank {
  font-size: 3em;
  line-height: 1em;
  font-family: 'Bender Bold';
}

.team-rank-label,
.team-amount-label {
  color: #999;
  font-size: 0.8em;
  text-transform: uppercase;
}

.match-stats {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #1c1c1a;
  border-bottom: 2px solid #000;
  padding: 15px;
}

.match-stat > span {
  display: block;
  text-align: center;
  color: #999;
  line-height: 1em;
}

.match-stat-value {
  font-family: 'Bender Bold';
  font-size: 1.5em;
  padding-bottom: 5px;
}

.match-stat-label {
  text-transform: uppercase;
  font-size: 0.6em;
}

.team-players-label {
  font-family: 'Bender Bold';
  line-height: 1em;
  font-size: 0.9em;
  color: #585858;
  text-transform: uppercase;
  padding: 5px 20px;
  background: rgba(21, 21, 21, 0.9);
  border-bottom: 1px solid #222;
}

.team-player {
  padding: 15px;
  background: #161614;
  font-family: 'Bender';
  font-style: normal;
  border-bottom: 2px solid rgba(9, 9, 7, 0.9);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.team-player-link img {
  width: 30px;
  height: auto;
  margin-right: 10px;
}

.team-player-link {
  color: #fff !important;
  text-decoration: none;
}

.team-player-social {
  display: flex;
  align-items: center;
}

.team-player-social a {
  border-radius: 4px;
  overflow: hidden;
  display: inline-block;
  margin-left: 10px;
}

.team-player-social img {
  width: 30px;
  height: auto;
}

.player-socials {
  display: flex;
  align-items: center;
  padding-top: 5px;
}

.player-socials a {
  display: inline-block;
  overflow: hidden;
  border-radius: 4px;
  margin-right: 10px;
}

.player-socials a img {
  width: 30px;
  height: auto;
}

.multi-day-selector {
  padding: 20px;
}

.multi-day-selector-desc {
  display: block;
  padding-bottom: 20px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media only screen and (max-width: 450px) {
  .mobile-only {
    display: block !important;
  }
  .mobile-only.inline-block {
    display: inline-block !important;
  }
  .task-header {
    display: block;
  }
  .task-header .task-ends {
    padding-top: 5px;
    display: block;
  }
}

@media only screen and (min-width: 451px) {
  .desktop-only {
    display: block !important;
  }
  .desktop-only.inline-block {
    display: inline-block !important;
  }
}

@media only screen and (min-width: 550px) {
  .news-nav .story a,
  .news-nav .story .news-anchor {
    display: flex;
  }

  .news-nav .story a .news-thumb {
    width: 25%;
    padding-top: 14%;
    margin-bottom: 0;
  }

  .task-details {
    flex-direction: row;
  }

  .task-map {
    margin-right: 15px;
    width: auto;
    flex-basis: 120px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .events-row-thumb {
    width: 100px;
    height: 100px;
    min-width: 100px;
  }

  .events-row-thumb img {
    width: 100px;
    height: auto;
  }

  .events-row-title {
    padding-top: 0;
    font-size: 1.2em;
    line-height: 1.1em;
  }
  .events-row-location span {
    font-size: 0.9em;
  }
  .team-header .team-desc {
    display: flex;
    align-items: center;
  }
}

@media only screen and (min-width: 768px) {
  a.logo,
  a.logo:hover,
  a.logo:active,
  a.logo:visited {
    display: inline-block;
    padding: 20px 0 20px 65px;
    height: auto;
    background: url(../img/fence-logo.png) no-repeat center left;
    color: #7ab903;
    font-family: 'Khand', sans-serif;
    text-transform: uppercase;
    font-size: 2.25em;
    line-height: 1em;
    font-weight: 600;
    text-decoration: none;
    background-size: 52px auto;
  }

  .mobile-menu a.logo,
  .mobile-menu a.logo:hover,
  .mobile-menu a.logo:active,
  .mobile-menu a.logo:visited {
    font-size: 1.25em;
  }

  .logo .text {
    display: inline-block;
  }

  .header-container header {
    padding: 0;
  }

  .main article {
    float: left;
    width: 57%;
  }

  .main aside {
    float: right;
    width: 28%;
  }
  .main-container .social-links {
    display: flex;
  }
  .avatar span {
    padding-left: 15px;
    display: inline-block;
  }
  .overlay-banner .overlay-league-run {
    display: flex;
  }
  .overlay-banner {
    position: relative;
    padding-top: 34.74%;
    min-height: 400px;
    z-index: 1;
    overflow: hidden;
  }

  .overlay-banner-text {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
  }
  .run-modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
  }

  .top-vodders {
    display: flex;
    align-items: center;
  }

  .top-vodder {
    margin-right: 15px;
    margin-bottom: 0;
  }

  .tournaments-panel .events-row {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .events-row {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 2px solid #000;
  }

  .events-row:last-child {
    border-bottom: none;
  }

  .events-row-info {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 0;
    margin: 0;
    background: none;
  }

  .event-page-details {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    overflow: auto;
  }

  .event-status {
    font-size: 0.9em;
    padding: 5px 15px;
  }

  .event-detail {
    float: none;
    width: auto;
  }

  .event-detail:nth-child(odd) {
    margin-right: 0;
    margin-bottom: 0;
  }

  .match-header-team-logo {
    width: 80px;
  }

  .match-header-team-name {
    font-size: 1em;
  }

  .match-two-up {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .match-two-up > * {
    width: calc(50% - 10px);
    padding: 15px 20px;
    font-size: 1em;
    margin-bottom: 0;
  }

  .match-vod-link {
    background: url(../img/icon-new-tab.png) no-repeat 20px center;
    padding-left: 55px;
    background-size: 24px auto;
    background-color: #18141c;
  }

  .match-head-header {
    padding: 20px 40px;
  }

  .match-head-team span {
    font-size: 1em;
    line-height: 1em;
  }

  .match-head-score-value {
    font-size: 3em;
  }

  .match-head-score-label {
    font-size: 1em;
  }

  .match-head-score {
    padding-right: 0;
  }

  .match-head-match {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .match-head-thumb {
    display: flex;
    align-items: center;
  }

  .match-head-thumb-img {
    width: 50px;
    height: 50px;
    padding: 0;
    margin: 0;
    background-size: 400% auto;
  }

  .match-head-info {
    padding: 0 0 0 10px;
  }

  .match-past-team {
    width: 50%;
    border-right: 2px solid #000;
  }

  .match-past-matches {
    background-color: #0e0e0d;
    display: flex;
    align-items: flex-start;
  }

  .match-past-match-info {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .match-past-date {
    padding-right: 20px;
  }
  .match-header-datetime {
    font-size: 0.9em;
  }
  .match-header-score {
    font-size: 3em;
  }

  .events-stats-filters {
    justify-content: right;
  }
  .team-header .team-details {
    padding-top: 0;
  }
}

@media only screen and (max-width: 600px) {
  .rankings-modal .header {
    display: block;
  }
  .rankings-modal .league-ranks {
    padding-top: 20px;
  }
  .cotw-banner .content-panel {
    display: block;
  }
  .cotw-ctas {
    align-items: center;
    padding-top: 20px;
  }
  .cotw-ctas a {
    display: block;
    width: 100%;
  }
  .cotw-ctas a button {
    width: 100%;
    display: block;
  }
}

@media only screen and (max-width: 900px) {
  .rankings-modal .MuiGrid-item {
    padding-top: 0 !important;
  }
  .rankings-modal .MuiGrid-item:first-child {
    padding-top: 40px !important;
  }
  .rankings-modal .MuiGrid-container .league-week-banner-text {
    padding: 0;
  }
  .rankings-modal
    .MuiGrid-container
    .MuiGrid-item:last-child
    .league-week-banner-text {
    padding: 0 0 20px 0;
  }
}

@media only screen and (max-width: 980px) {
  .get-started-banner-container {
    display: none;
  }
}

@media only screen and (min-width: 900px) {
  .comparison-desc .paragraph {
    padding: 0 100px 40px;
  }
  .value-prop-text .paragraph {
    font-size: 1.1em;
    padding: 0;
  }
  .value-prop-inner {
    padding: 40px 20px;
  }

  .league-prizes-cta .btn-primary {
    width: auto;
  }

  .sub-cta {
    text-align: right;
  }
}

@media only screen and (min-width: 996px) {
  .league-run-banner-container .wrapper {
    flex-direction: row;
  }
  .league-run-banner-container .wrapper > span {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1300px) {
  .wrapper {
    width: 1220px;
    margin: 0 auto;
  }
  .main {
    display: flex;
    padding-bottom: 50px;
  }
  .main .sidebar-left {
    width: 18%;
  }
  .main .middle-content {
    width: 59%;
  }
  .main .content-expanded {
    width: 82%;
    padding: 0 0 0 15px;
  }
  .main .sidebar-right {
    width: 23%;
  }
  .middle-content {
    padding: 0 15px;
  }
  .banner-text {
    padding: 20px 255px 20px 20px;
  }
  .btn-login {
    display: inline-block !important;
  }
  .btn-menu {
    display: none;
  }
  .desktop {
    display: block !important;
  }
  .main {
    flex-direction: row;
  }

  .sidebar-left {
    order: 1;
  }

  .middle-content,
  .content-expanded {
    order: 2;
  }

  .sidebar-right {
    order: 3;
  }
  .aside-streams {
    margin-top: 0;
  }

  .run-modal {
    width: 1100px;
    height: auto;
  }

  .value-prop-inner {
    padding: 60px 40px;
  }

  .howto-modal {
    height: auto;
    overflow-y: hidden;
  }
}

@media only screen and (min-width: 1930px) {
  .wrapper {
    width: 1700px; /* 1140px - 10% for margins */
    margin: 0 auto;
  }
  .main .sidebar-left {
    width: 15%;
  }
  .main .middle-content {
    width: 63%;
  }
  .main .content-expanded {
    width: 85%;
  }
  .main .sidebar-right {
    width: 22%;
  }

  .news-nav .story a .news-thumb,
  .news-nav .story .news-anchor .news-thumb {
    width: 25%;
    border-radius: 3px;
  }
}

/* ==========================================================================
 Helper classes
 ========================================================================== */

.hidden {
  display: none !important;
  visibility: hidden;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

/* ==========================================================================
 Print styles
 ========================================================================== */

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
  }

  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: '';
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}
